import React from 'react';

type RegisterProps = {}
type RegisterState = {
    email: string;
    password: string;
    confirmation: string;
}
export class Register extends React.Component<RegisterProps, RegisterState> {

    constructor(props: RegisterProps) {
        super(props);

        this.state = {
            email: "",
            password: "",
            confirmation: ""
        };

    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        } as RegisterState);
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        /*
        TODO: get minimum username length
        Everest may have its own limit
        */
        if (this.state.email.length < 3)
        {
            return false;
        }
        else if (this.state.password.length < 8) 
        {
            return false;
        }
        else if (this.state.confirmation !== this.state.password)
        {
            alert("Passwords do not match");
            return false;
        }

        fetch(process.env.REACT_APP_API_URL + '/User', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify(this.state)
            }
        )
        .then((response) => {
            if (!response.ok) {
                throw new Error("" + response.status);
            }

            //this.login();
            window.location.hash = "/thankyou"
        })
        .catch((error) => {
            switch (error.message) {
                case "409": {
                    alert("Sorry, that email is already taken");
                    break;
                }
                default: {
                    alert("Unknown error");
                    break;
                }
            }
        });
    };

    login() {
        fetch(process.env.REACT_APP_API_URL + '/Token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            }
        )
        .then((response) => {
            if (!response.ok) {
                throw new Error("" + response.status);
            }

            return response.json();
        })
        .then((data) => this.authorize(data))
        .catch((error) => alert(error));
    }

    authorize(data: any) {
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        window.location.hash = "/";
    }

    render() {
        return (
            <section>

                <form className="SignupForm SignupForm--Register" onSubmit={this.handleSubmit}>
                    <label>Email</label>
                    <input required 
                        name="email" 
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className="TextInput TextInput--Minimal"
                        minLength={3}/>

                    <label>Password</label>
                    <input required 
                        name="password" 
                        type="password" 
                        value={this.state.password}
                        onChange={this.handleChange}
                        className="TextInput TextInput--Minimal"
                        minLength={8}/>

                    <label>Confirm password</label>
                    <input required 
                        name="confirmation" 
                        type="password"
                        value={this.state.confirmation}
                        className="TextInput TextInput--Minimal"
                        onChange={this.handleChange}
                        minLength={8} />

                    <button type="submit"
                        className="Button Button--Primary">Sign Up</button>
                </form>
            </section>
        );
    }
}