import React from 'react';
import { SubMenuItem } from './Menu';
import { Survey } from './Fragments/Survey';
import { SongCompetition } from './Fragments/SongCompetition';
import { authorizedFetch } from '../Fetch';

type NewsItem = {
    id: string;
    header: string;
    contents: string;
    startDate: string;
}

type NewsState = {
    news: NewsItem[];
    items: JSX.Element[];
}

class News extends React.Component<any, NewsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            news: [],
            items: []
        };
    }

    handleExpandNews = (e: React.MouseEvent<HTMLElement>) => {
        console.log("expanding");
        let news = e.currentTarget.querySelector(".News--Preview")!;
        news.classList.add("News--Expanded");
    };

    componentDidMount() {

        authorizedFetch('/News/Active', 'GET')
        .then((response) => response.json())
        .then((data) =>  {

            let news : NewsItem[] = data.map((d: any) => ({
                id: d.id,
                header: d.header,
                contents: d.contents,
                startDate: new Date(d.startDate).toISOString().slice(0, 10)
            }));

            let items = news.map((item, i) => 
                <div key={i} onClick={this.handleExpandNews}>
                    <img id={'news_' + item.id}/>
                    <h1 className="News__Header">{item.header}</h1>
                    <p className="News--Preview">{item.contents}</p>
                </div>
            );

            this.setState({
                news: news,
                items: items
            });
            this.loadNewsGraphics(news);
        })
        .catch((error) => alert(error));
    }

    loadNewsGraphics(news: NewsItem[]) {
        let promises = [];

        for (let item of news) {
            let path = `/News/${item.id}/graphic`;
            promises.push(authorizedFetch(path, 'GET')
                .then((response) => response.blob()));            
        }

        Promise.all(promises)
        .then((images) => {
            for (let i = 0; i < news.length; i++) {
                let graphic = document.getElementById('news_' + news[i].id)! as HTMLImageElement;
                graphic.src = URL.createObjectURL(images[i]);
            }
        });
    }

    render() {
        return (
            <section className="Fragment">
                <div className="NewsList">
                    {this.state.items}
                </div>
                
            </section>
        );
    }
}

class FeaturedVideos extends React.Component {
    render() {
        return (
            <p>This is a placeholder featured videos</p>
        );
    }
}

type CurrentEventProps = {
    fragment: string
}

export class CurrentEvent extends React.Component<CurrentEventProps> {

    render() {

        let fragment = null;

        switch (this.props.fragment) {
            case "news": {
                fragment = <News />;
                break;
            }
            case "survey": {
                fragment = <Survey />;
                break;
            }
            case "competition": {
                fragment = <SongCompetition />;
                break;
            }
            case "videos": {
                fragment = <FeaturedVideos />;
                break;
            }
        }

        return (
            <section>
                <div className='Submenu'>
                    <SubMenuItem header="News" 
                        main="now" 
                        route="news" 
                        isActive={this.props.fragment === "news"}/>
                    <SubMenuItem header="Survey" 
                        main="now" 
                        route="survey" 
                        isActive={this.props.fragment === "survey"}/>
                    <SubMenuItem header="Competition" 
                        main="now" 
                        route="competition" 
                        isActive={this.props.fragment === "competition"}/>
                    
                </div>

                {fragment}

            </section>
        );

        /*
        Todo: <SubMenuItem header="Videos" 
                        main="now" 
                        route="videos"
                        isActive={this.props.fragment === "videos"} />
        */
    }
}