import React from 'react';
import {SurveyStep, RadioButton, CheckBox, DisagreeAgreeSelector} from './FormElements';
import { authorizedFetchWithBody } from '../../Fetch';

interface SurveyState {
    started: boolean;
    currentPage: number;
    
    Age: string;
    Experience: string;
    PreferredProtection: string[]; //checkbox, multiple allowed
    UserType: string[]; //checkbox, multiple allowed
    UsedUnlicensed: string; 
    CurrentlyUsing: string;
    PiratingStance: string;
    OpinionPirateReason: string[]; //checkbox, multiple allowed
    OpinionPirateReason_other: string;
    SameAsMicrophone: string;
    StealMicrophone: string;
    OkToUsePirated: string;
    OkToBuyAfterPirate: string;
    OkToShareLegit: string;
    OkToCopyMusic: string;
    CannotAffordThen: string[]; //checkbox, multiple allowed
    HowCrackCompares: string;
    HowLongTillCrack: string;
    Impact: string;
    Approach: string;
    CurrentlyUsedSoftware: string;
    Feedback: string;
}

export class Survey extends React.Component<any, SurveyState> {
    private maxPage = 10;

    constructor(props: any) {
        super(props);

        this.state = {} as SurveyState;
        this.state = {...this.state, 
            started: false,
            currentPage: 1,
            PreferredProtection: [],
            UserType: [],
            OpinionPirateReason: [],
            CannotAffordThen: []
        };

        this.handleStart = this.handleStart.bind(this);
        this.previousSurveyPage = this.previousSurveyPage.bind(this);
        this.nextSurveyPage = this.nextSurveyPage.bind(this);
    }

    handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let name = event.currentTarget.name;
        let value = event.currentTarget.value;

        switch (name) {
            case "PreferredProtection":
            case "UserType":
            case "OpinionPirateReason":
            case "CannotAffordThen": {
                let values = Array.from(document.querySelectorAll("[name^=" + name + "]:checked"))
                    .map(input => (input as HTMLInputElement).value);
                this.setState(prevState => { return {
                    ...prevState,
                    [name]: values
                }});
                break;
            }
            default: {
                this.setState(prevState => { return {
                    ...prevState,
                   [name]: value
                }});
            }
        }
    };

    submitSurvey = () => {
        for (let i = 0; i < this.maxPage; i++) {
            if (!this.getCanProgress(i + 1)) {
                return;
            }
        }

        authorizedFetchWithBody('/Survey', 
            'POST',
            JSON.stringify(this.state as SurveyData),
            'application/json'
        )
        .then((response) => alert("Ok"))
        .catch((error) => alert("ERROR"));
    };

    handleStart() {
        this.setState({started: true});
    }

    previousSurveyPage() {
        if (this.state.currentPage > 1) {
            this.setState({currentPage: this.state.currentPage - 1});
        }
    }

    nextSurveyPage() {
        if (this.state.currentPage < this.maxPage) {
            this.setState({currentPage: this.state.currentPage + 1});
        }
    }

    render() {
        if (this.state.started) {
            return this.renderSurvey();
        }

        return (
            <section className="Fragment">

                <div className="SpaceAtTop">

                    <h1>Let's Talk Piracy</h1>

                    <p className="Question">
                    The International Music Software Trade Association (IMSTA) designed the “Let’s Talk Piracy” Survey in order to get a better understanding of the motivators behind software piracy. 
                    </p>
                    <p className="Question">
                    The information collected herein will be used by IMSTA for research purposes only and will NOT be used to take legal action against the survey participants. 
                    </p>
                    <p className="Question">
                    Therefore, please be honest when answering these questions. IMSTA also proclaims that your private information will not be shared with anyone and that only the results of this survey will be published and shared with member companies.
                    </p>

                    <button className="ExternalLink FullWidth" 
                        type="button"
                        onClick={this.handleStart}
                        >Start</button>
                </div>
            </section>
        );
    }

    renderSurvey() {
        switch (this.state.currentPage) {
            case 1: {
                return this.renderPage1();
            }
            case 2: {
                return this.renderPage2();
            }
            case 3: {
                return this.renderPage3();
            }
            case 4: {
                return this.renderPage4();
            }
            case 5: {
                return this.renderPage5();
            }
            case 6: {
                return this.renderPage6();
            }
            case 7: {
                return this.renderPage7();
            }
            case 8: {
                return this.renderPage8();
            }
            case 9: {
                return this.renderPage9();
            }
            case 10: {
                return this.renderPage10();
            }
            default: {
                return this.renderPage1();
            }
        }
    }

    renderPageNavigation(canProgress: boolean) {
        let backButtonClass = "Survey-Navigation-Back";
        
        if (this.state.currentPage <= 1) {
            backButtonClass += " hidden";
        }

        let nextButton = null;

        if (canProgress) {
            nextButton = <button className="Survey-Navigation-Next"
                onClick={this.nextSurveyPage}
                type="button">Next</button>;
        }
        else {
            nextButton = <button className="Survey-Navigation-Next Survey-Navigation-Next--disabled"
                type="button">Next</button>;
        }

        return (
            <div className="Survey-Navigation">
                    <button className={backButtonClass}
                        onClick={this.previousSurveyPage}
                        type="button">Back</button>
                    
                    {nextButton}                    
                </div>
        );
    }

    renderPageProgress() {

        let steps = [];

        for (let i = 0; i < this.maxPage; i++) {
            let page = i + 1;
            let canProgress = this.getCanProgress(page);
            steps.push(<SurveyStep key={i} step={page} active={this.state.currentPage} completed={canProgress} />)
        }

        return (
            <div className="Survey-Feedback">

                <div className="SurveyProgress">
                    <hr className="Progress-Line" />
                    {steps}
                </div>
            </div>
        );
    }

    renderSubmitSurvey(canProgress: boolean) {

        let nextButton = null;

        if (canProgress) {
            nextButton = <button className="Survey-Navigation-Next"
                onClick={this.submitSurvey}
                type="button">Submit</button>
        }
        else {
            nextButton = <button className="Survey-Navigation-Next Survey-Navigation-Next--disabled"
                type="button">Submit</button>
        }

        return (
            <div className="Survey-Navigation">
                <button className="Survey-Navigation-Back"
                    onClick={this.previousSurveyPage}
                    type="button">Back</button>

                {nextButton}
            </div>
        );
    }

    renderPage1() {
        let canProgress = this.getCanProgress(1);

        return (
            <section className="Fragment">

                <div>

                    <div>
                        <p className="Question">1. What is your age group?</p>

                        <div className="RadioGroup">
                            <RadioButton name="Age" label="65+"
                                value="65+" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton name="Age" label="55-64"
                                value="55-64" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton  name="Age" label="45-54"
                                value="45-54" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton name="Age" label="35-44"
                                value="35-44" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton  name="Age" label="25-34"
                                value="25-34" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton  name="Age" label="18-24"
                                value="18-24" current={this.state.Age} onChange={this.handleChange} />
                            <RadioButton  name="Age" label="&lt; 18"
                                value="<18" current={this.state.Age} onChange={this.handleChange} />
                        </div>
                    </div> 

                    <div>
                        <p className="Question">2. How many years have you been using music software?</p>

                        <div className="RadioGroup Group--no-wrap">
                            <RadioButton  name="Experience" label="&lt; 1"
                                value="<1" current={this.state.Experience} onChange={this.handleChange} />
                            <RadioButton  name="Experience" label="1-3"
                                value="1-3" current={this.state.Experience} onChange={this.handleChange} />
                            <RadioButton  name="Experience" label="3-5"
                                value="3-5" current={this.state.Experience} onChange={this.handleChange} />
                            <RadioButton name="Experience" label="&gt; 5"
                                value=">5" current={this.state.Experience} onChange={this.handleChange} />
                            <RadioButton  name="Experience" label="&gt; 10"
                                value=">10" current={this.state.Experience} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div>
                        <p className="Question">3. Which software copy protection methods do you prefer? (May choose more than one)</p>

                        <div className="CheckboxGroup">
                            <CheckBox name="PreferredProtection" label="Virtual Dongle" 
                                current={this.state.PreferredProtection} onChange={this.handleChange} />
                            <CheckBox name="PreferredProtection" label="Serial Number" 
                                current={this.state.PreferredProtection} onChange={this.handleChange} />
                            <CheckBox name="PreferredProtection" label="Hardware Dongle" 
                                current={this.state.PreferredProtection} onChange={this.handleChange} />
                            <CheckBox name="PreferredProtection" label="Challenge/Response" 
                                current={this.state.PreferredProtection} onChange={this.handleChange} />
                            <CheckBox name="PreferredProtection" label="CD/DVD ROM" 
                                current={this.state.PreferredProtection} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderPage2() {

        let canProgress = this.getCanProgress(2);

        return (
            <section className="Fragment">

                <div>
                    <p className="Question">4. Which music software user types best describe you?
                        Please choose as many options as you need for the most accurate description
                    </p>

                    <div className="CheckboxGroup">
                        <CheckBox name="UserType" label="Instructor" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Student" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Hobbyist" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Semi-professional" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Professional" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Songwriter" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Music Producer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Audio Engineer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Mixing Engineer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Mastering Engineer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Live Sound Engineer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Sound Designer" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Home Studio Operator" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="DJ" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Broadcaster" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Podcaster" 
                            current={this.state.UserType} onChange={this.handleChange} />
                        <CheckBox name="UserType" label="Recording Studio Operator" 
                            current={this.state.UserType} onChange={this.handleChange} />
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}

            </section>
        );
    }

    renderPage3() {
        let canProgress = this.getCanProgress(3);

        return (
            <section className="Fragment">
                <div>
                    <div>
                        <p className="Question">5. Have you ever used unlicensed music software?</p>

                        <div className="RadioGroup RadioGroup--unbounded">
                            <RadioButton name="UsedUnlicensed" label="Prefer not to answer" 
                                current={this.state.UsedUnlicensed} onChange={this.handleChange} />
                            <RadioButton name="UsedUnlicensed" label="No" 
                                current={this.state.UsedUnlicensed} onChange={this.handleChange} />
                            <RadioButton name="UsedUnlicensed" label="Yes" 
                                current={this.state.UsedUnlicensed} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div>
                        <p className="Question">6. Do you currently use unlicensed music software?</p>

                        <div className="RadioGroup RadioGroup--unbounded">
                            <RadioButton name="CurrentlyUsing" label="Prefer not to answer" 
                                current={this.state.CurrentlyUsing} onChange={this.handleChange}/>
                            <RadioButton name="CurrentlyUsing" label="No" 
                                current={this.state.CurrentlyUsing} onChange={this.handleChange} />
                            <RadioButton name="CurrentlyUsing" label="Yes" 
                                current={this.state.CurrentlyUsing} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div>
                        <p className="Question">7. Please choose the statement you most agree with:</p>

                        <div className="RadioGroup RadioGroup--unbounded ColumnGroup">
                            <RadioButton name="PiratingStance" label="It is wrong to use pirated music software"
                                current={this.state.PiratingStance} onChange={this.handleChange} />
                            <RadioButton name="PiratingStance" label="It is OK to occasionally use pirated music software"
                                current={this.state.PiratingStance} onChange={this.handleChange} />
                            <RadioButton name="PiratingStance" label="It is completely OK to use pirated music software"
                                current={this.state.PiratingStance} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>
                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderPage4() {

        let canProgress = this.getCanProgress(4);

        return (
            <section className="Fragment">
                <div>
                    <p className="Question">8. In your opinion, why do some people use pirated music software? (Can select multiple)</p>

                    <div className="CheckboxGroup ColumnGroup RadioGroup--unbounded">
                        <CheckBox name="OpinionPirateReason" label="They want to try before they buy"
                            current={this.state.OpinionPirateReason} onChange={this.handleChange} />
                        <CheckBox name="OpinionPirateReason" label="They do not want to pay for it even if they could afford it"
                            current={this.state.OpinionPirateReason} onChange={this.handleChange} />
                        <CheckBox name="OpinionPirateReason" label="They cannot afford to buy the authorized version"
                            current={this.state.OpinionPirateReason} onChange={this.handleChange} />
                        <CheckBox name="OpinionPirateReason" label="Other"
                            current={this.state.OpinionPirateReason} onChange={this.handleChange} />

                        <p>If other, please specify: </p>
                        <textarea className="Fix" name="OpinionPirateReason_other" rows={5}
                            value={this.state.OpinionPirateReason_other} onChange={this.handleChange}></textarea>
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderPage5() {
        let canProgress = this.getCanProgress(5);

        return (
            <section className="Fragment">
                <div>
                    <DisagreeAgreeSelector 
                        header="9. Using pirated music software is the same as using a stolen physical product such as a microphone."                
                        name="SameAsMicrophone"
                        current={this.state.SameAsMicrophone}
                        onChange={this.handleChange} />

                    <DisagreeAgreeSelector 
                        header="10. It is OK to steal a microphone if you cannot afford to buy it:"
                        name="StealMicrophone"
                        current={this.state.StealMicrophone}
                        onChange={this.handleChange} />

                    <DisagreeAgreeSelector
                        header="11. It is OK to use pirated music software if you cannot afford to buy it"
                        name="OkToUsePirated"
                        current={this.state.OkToUsePirated}
                        onChange={this.handleChange} />
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );  
    }

    renderPage6() {
        let canProgress = this.getCanProgress(6);

        return (
            <section className="Fragment">
                <div>
                    <DisagreeAgreeSelector
                        header="12. It is OK to use pirated music software only if you buy the same product after you try it"
                        name="OkToBuyAfterPirate"
                        current={this.state.OkToBuyAfterPirate}
                        onChange={this.handleChange} />

                    <DisagreeAgreeSelector
                        header="13. It is OK to share legitimately purchased and installed music software with friends and colleagues"
                        name="OkToShareLegit"
                        current={this.state.OkToShareLegit}
                        onChange={this.handleChange} />

                    <DisagreeAgreeSelector
                        header="14. It is OK to copy music CDs and share them with friends, or make the mp3 files available on the internet for everyone to share"
                        name="OkToCopyMusic"
                        current={this.state.OkToCopyMusic}
                        onChange={this.handleChange} />
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderPage7() {
        let canProgress = this.getCanProgress(7);

        return (
            <section className="Fragment">
                <div>
                    <p className="Question">15. If a person cannot afford to buy the desired music software, he/she should: (Can select multiple)</p>

                    <div className="CheckboxGroup">
                        <CheckBox name="CannotAffordThen" label="Find the cracked version of the software and use it"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                        <CheckBox name="CannotAffordThen" label="Borrow it from a friend who bought it"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                        <CheckBox name="CannotAffordThen" label="Buy a more affordable product"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                        <CheckBox name="CannotAffordThen" label="Wait until he/she could afford it, then buy it"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                        <CheckBox name="CannotAffordThen" label="Use a cracked version until he/she could afford to buy it"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                        <CheckBox name="CannotAffordThen" label="Use Freeware"
                            current={this.state.CannotAffordThen} onChange={this.handleChange} />
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        )
    }

    renderPage8() {
        let canProgress = this.getCanProgress(8);

        return (
            <section className="Fragment">
                <div>
                    <div>
                        <p className="Question">16. How do the cracked versions of music software compare to the authorized versions?</p>

                        <div className="RadioGroup RadioGroup--unbounded RadioGroup--condensed ColumnGroup">
                            <RadioButton name="HowCrackCompares" label="Inferior because of bugs and/or viruses"
                                current={this.state.HowCrackCompares} onChange={this.handleChange} />
                            <RadioButton name="HowCrackCompares" label="Cracked versions are better"
                                current={this.state.HowCrackCompares} onChange={this.handleChange} />
                            <RadioButton name="HowCrackCompares" label="I do not know"
                                current={this.state.HowCrackCompares} onChange={this.handleChange} />
                            <RadioButton name="HowCrackCompares" label="There is very little difference"
                                current={this.state.HowCrackCompares} onChange={this.handleChange} />
                            <RadioButton name="HowCrackCompares" label="There is no difference"
                                current={this.state.HowCrackCompares} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div>
                        <p className="Question">17. How long does it take for a cracked version to become available after a new release?</p>

                        <div className="RadioGroup RadioGroup--unbounded">
                            <RadioButton name="HowLongTillCrack" label="Very slow"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                            <RadioButton name="HowLongTillCrack" label="Fairly slow"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                            <RadioButton name="HowLongTillCrack" label="I do not know"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                            <RadioButton name="HowLongTillCrack" label="Fairly quick"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                            <RadioButton name="HowLongTillCrack" label="Very quick"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                            <RadioButton name="HowLongTillCrack" label="Before it is released"
                                current={this.state.HowLongTillCrack} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderPage9() {
        let canProgress = this.getCanProgress(9);

        return (
            <section className="Fragment">
                <div>
                    <div>
                        <p className="Question">18. How does piracy impact the music software companies?</p>

                        <div className="RadioGroup RadioGroup--unbounded RadioGroup--condensed">
                            <RadioButton name="Impact" label="It could force them to go out of business"
                                current={this.state.Impact} onChange={this.handleChange} />
                            <RadioButton name="Impact" label="It could force them to layoff employees"
                                current={this.state.Impact} onChange={this.handleChange} />
                            <RadioButton name="Impact" label="They feel the impact but will survive"
                                current={this.state.Impact} onChange={this.handleChange} />
                            <RadioButton name="Impact" label="It has very little impact"
                                current={this.state.Impact} onChange={this.handleChange} />
                            <RadioButton name="Impact" label="It has no impact"
                                current={this.state.Impact} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div>
                        <p className="Question">19. Which music software products do you currently use? Please enter both the manufacturer and the product:</p>

                        <textarea name="CurrentlyUsedSoftware" rows={8} className="Feedback Fix"
                            value={this.state.CurrentlyUsedSoftware} onChange={this.handleChange}></textarea>
                    </div>
                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        )
    }

    renderPage10() {
        let canProgress = this.getCanProgress(10);

        return (
            <section className="Fragment">
                
                <div>
                    <p className="Question">20. Which of the following statements do you agree with?</p>

                    <div className="RadioGroup RadioGroup--unbounded RadioGroup--condensed ColumnGroup">
                        <RadioButton name="Approach" label="I like IMSTA's educational approach in facing piracy"
                            current={this.state.Approach} onChange={this.handleChange} />
                        <RadioButton name="Approach" label="Education wont work, legal action is the way to face pirates"
                            current={this.state.Approach} onChange={this.handleChange} />
                        <RadioButton name="Approach" label="Education plus enforcement works best"
                            current={this.state.Approach} onChange={this.handleChange} />
                        <RadioButton name="Approach" label="I believe neither education nor enforcement will work"
                            current={this.state.Approach} onChange={this.handleChange} />
                    </div>

                    <p className="Question">21. Feedback/Comments:</p>

                    <textarea name="Feedback" rows={8} className="Feedback"
                        value={this.state.Feedback} onChange={this.handleChange}></textarea>
                </div>

                {this.renderSubmitSurvey(canProgress)}
                {this.renderPageProgress()}
            </section>
        )
    }

    getCanProgress(pageIndex: number) {
        switch (pageIndex) {
            case 1: {
                return !!this.state.Age 
                    && !!this.state.Experience
                    && this.state.PreferredProtection.length > 0;
            }
            case 2: {
                return this.state.UserType.length > 0;
            }
            case 3: {
                return !!this.state.UsedUnlicensed
                    && !!this.state.CurrentlyUsing
                    && !!this.state.PiratingStance;
            }
            case 4: {
                return this.state.OpinionPirateReason.length > 0
                    && (!this.state.OpinionPirateReason.includes("Other")
                        || !!this.state.OpinionPirateReason_other);
            }
            case 5: {
                return !!this.state.SameAsMicrophone
                    && !!this.state.StealMicrophone
                    && !!this.state.OkToUsePirated;
            }
            case 6: {
                return !!this.state.OkToBuyAfterPirate
                    && !!this.state.OkToShareLegit
                    && !!this.state.OkToCopyMusic;
            }
            case 7: {
                return this.state.CannotAffordThen.length > 0;
            }
            case 8: {
                return !!this.state.HowCrackCompares
                    && !!this.state.HowLongTillCrack;
            }
            case 9: {
                return !!this.state.Impact
                    && !!this.state.CurrentlyUsedSoftware;
             //       && !!this.state.Approach;    
            }
            case 10: {
                //return !!this.state.CurrentlyUsedSoftware;
                return !!this.state.Approach;
            }
            default: return false;
        }
    }
}

interface SurveyData {
    Age: string;
    Experience: string;
    PreferredProtection: string[]; //checkbox, multiple allowed
    UserType: string[]; //checkbox, multiple allowed
    UsedUnlicensed: string; 
    CurrentlyUsing: string;
    PiratingStance: string;
    OpinionPirateReason: string[]; //checkbox, multiple allowed
    OpinionPirateReason_other: string;
    SameAsMicrophone: string;
    StealMicrophone: string;
    OkToUsePirated: string;
    OkToBuyAfterPirate: string;
    OkToShareLegit: string;
    OkToCopyMusic: string;
    CannotAffordThen: string[]; //checkbox, multiple allowed
    HowCrackCompares: string;
    HowLongTillCrack: string;
    Impact: string;
    Approach: string;
    CurrentlyUsedSoftware: string;
    Feedback: string;
}