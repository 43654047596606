import React from 'react';
import { authorizedFetch, authorizedFetchWithBody } from '../Fetch';
import { SubMenuItem } from './Menu';
import { timeStamp } from 'console';
import {SurveyStep, LabelledInput, RadioButton} from './Fragments/FormElements';
import './Account.css';

interface ProfileState {
    canRender: boolean;
    contests: string[];
    firstName: string;
    lastName: string;
    city: string;
    country: string;
    expertise: string;
    vocation: string;
    experience: string;
    birthYear: string;
}

interface ContestProps {
    contests: string[];
}

class Contests extends React.Component<ContestProps> {
    render() {
        return (
            <section>
                <h1>Song Contests Entered</h1>

                <ul>

                    {this.props.contests.map((contest, i) => 
                        <li key={i}>{contest}</li>)
                    }

                </ul>
            </section>  
        );
    }
}

type AboutMeState = {
    FirstName: string;
    LastName: string;
    City: string;
    Country: string;
    Expertise: string;
    Vocation: string;
    Experience: string;
    BirthYear: string;
}

class General extends React.Component<ProfileState, AboutMeState> {
    constructor(props: ProfileState) {
        super(props);

        this.state = {
            FirstName: props.firstName,
            LastName: props.lastName,
            City: props.city,
            Country: props.country,
            Expertise: props.expertise,
            Vocation: props.vocation,
            Experience: props.experience,
            BirthYear: props.birthYear
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let name = event.currentTarget.name;
        let value = event.currentTarget.value;

        this.setState(prevState => { return {
            ...prevState,
            [name]: value
        }});
    }

    handleSave = () => {
        authorizedFetchWithBody(
            "/User/Profile",
            "POST",
            JSON.stringify({
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                City: this.state.City,
                Country: this.state.Country,
                Expertise: Number.parseInt(this.state.Expertise),
                Vocation: Number.parseInt(this.state.Vocation),
                Experience: Number.parseInt(this.state.Experience),
                BirthYear: Number.parseInt(this.state.BirthYear)
            }),
            "application/json"
        )
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    render() {
        return (
            <section>
                <LabelledInput name="FirstName" label="First Name"
                    value={this.state.FirstName} handleChange={this.handleChange}
                    className="Question FullLabel SpaceAtTop" /> 

                <LabelledInput name="LastName" label="Last Name"
                    value={this.state.LastName} handleChange={this.handleChange}
                    className="Question FullLabel SpaceAtTop" />  

                <LabelledInput name="City" label="City"
                    value={this.state.City} handleChange={this.handleChange}
                    className="Question FullLabel SpaceAtTop" />  

                <div>
                    <label className="Question FullLabel SpaceAtTop--small">Country</label>
                    <select name="Country" className="FullHeightDropdown"
                        value={this.state.Country} onChange={this.handleChange} >
                        <option></option>
                        <option>AFGHANISTAN</option>
                        <option>ALBANIA</option>
                        <option>ALGERIA</option>
                        <option>AMERICAN SAMOA</option>
                        <option>ANDORRA</option>
                        <option>ANGOLA</option>
                        <option>ANGUILLA</option>
                        <option>ANTARCTICA</option>
                        <option>ANTIGUA AND BARBUDA</option>
                        <option>ARGENTINA</option>
                        <option>ARMENIA</option>
                        <option>ARUBA</option>
                        <option>AUSTRALIA</option>
                        <option>AUSTRIA</option>
                        <option>AZERBAIJAN</option>
                        <option>BAHAMAS</option>
                        <option>BAHRAIN</option>
                        <option>BANGLADESH</option>
                        <option>BARBADOS</option>
                        <option>BELARUS</option>
                        <option>BELGIUM</option>
                        <option>BELIZE</option>
                        <option>BENIN</option>
                        <option>BERMUDA</option>
                        <option>BHUTAN</option>
                        <option>BOLIVIA</option>
                        <option>BOSNIA AND HERZEGOVINA</option>
                        <option>BOTSWANA</option>
                        <option>BOUVET ISLAND</option>
                        <option>BRAZIL</option>
                        <option>BRITISH INDIAN OCEAN TERRITORY</option>
                        <option>BRUNEI DARUSSALAM</option>
                        <option>BULGARIA</option>
                        <option>BURKINA FASO</option>
                        <option>BURUNDI</option>
                        <option>CAMBODIA</option>
                        <option>CAMEROON</option>
                        <option>CANADA</option>
                        <option>CAPE VERDE</option>
                        <option>CAYMAN ISLANDS</option>
                        <option>CENTRAL AFRICAN REPUBLIC</option>
                        <option>CHAD</option>
                        <option>CHILE</option>
                        <option>CHINA</option>
                        <option>CHRISTMAS ISLAND</option>
                        <option>COCOS (KEELING) ISLANDS</option>
                        <option>COLOMBIA</option>
                        <option>COMOROS</option>
                        <option>CONGO</option>
                        <option>CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                        <option>COOK ISLANDS</option>
                        <option>COSTA RICA</option>
                        <option>CROATIA</option>
                        <option>CUBA</option>
                        <option>CYPRUS</option>
                        <option>CZECH REPUBLIC</option>
                        <option>DENMARK</option>
                        <option>DJIBOUTI</option>
                        <option>DOMINICA</option>
                        <option>DOMINICAN REPUBLIC</option>
                        <option>EAST TIMOR</option>
                        <option>ECUADOR</option>
                        <option>EGYPT</option>
                        <option>EL SALVADOR</option>
                        <option>EQUATORIAL GUINEA</option>
                        <option>ERITREA</option>
                        <option>ESTONIA</option>
                        <option>ETHIOPIA</option>
                        <option>FALKLAND ISLANDS (MALVINAS)</option>
                        <option>FAROE ISLANDS</option>
                        <option>FIJI</option>
                        <option>FINLAND</option>
                        <option>FRANCE</option>
                        <option>FRENCH GUIANA</option>
                        <option>FRENCH POLYNESIA</option>
                        <option>FRENCH SOUTHERN TERRITORIES</option>
                        <option>GABON</option>
                        <option>GAMBIA</option>
                        <option>GEORGIA</option>
                        <option>GERMANY</option>
                        <option>GHANA</option>
                        <option>GIBRALTAR</option>
                        <option>GREECE</option>
                        <option>GREENLAND</option>
                        <option>GRENADA</option>
                        <option>GUADELOUPE</option>
                        <option>GUAM</option>
                        <option>GUATEMALA</option>
                        <option>GUINEA</option>
                        <option>GUINEA-BISSAU</option>
                        <option>GUYANA</option>
                        <option>HAITI</option>
                        <option>HEARD AND MCDONALD ISLANDS</option>
                        <option>HONDURAS</option>
                        <option>HONG KONG</option>
                        <option>HUNGARY</option>
                        <option>ICELAND</option>
                        <option>INDIA</option>
                        <option>INDONESIA</option>
                        <option>IRAN</option>
                        <option>IRAQ</option>
                        <option>IRELAND</option>
                        <option>ISRAEL</option>
                        <option>ITALY</option>
                        <option>IVORY COAST</option>
                        <option>JAMAICA</option>
                        <option>JAPAN</option>
                        <option>JORDAN</option>
                        <option>KAZAKHSTAN</option>
                        <option>KENYA</option>
                        <option>KIRIBATI</option>
                        <option>KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF</option>
                        <option>KOREA,REPUBLIC OF</option>
                        <option>KUWAIT</option>
                        <option>KYRGYZSTAN</option>
                        <option>LAO PEOPLES DEMOCRATIC REPUBLIC</option>
                        <option>LATVIA</option>
                        <option>LEBANON</option>
                        <option>LESOTHO</option>
                        <option>LIBERIA</option>
                        <option>LIBYAN ARAB JAMAHIRIYA</option>
                        <option>LIECHTENSTEIN</option>
                        <option>LITHUANIA</option>
                        <option>LUXEMBOURG</option>
                        <option>MACAO</option>
                        <option>MACEDONIA,THE FORMER YUGOSLAV REPUBLIC</option>
                        <option>MADAGASCAR</option>
                        <option>MALAWI</option>
                        <option>MALAYSIA</option>
                        <option>MALDIVES</option>
                        <option>MALI</option>
                        <option>MALTA</option>
                        <option>MARSHALL ISLANDS</option>
                        <option>MARTINIQUE</option>
                        <option>MAURITANIA</option>
                        <option>MAURITIUS</option>
                        <option>MEXICO</option>
                        <option>MICRONESIA, FEDERATED STATES OF</option>
                        <option>MOLDOVA, REPUBLIC OF</option>
                        <option>MONACO</option>
                        <option>MONGOLIA</option>
                        <option>MONTSERRAT</option>
                        <option>MOROCCO</option>
                        <option>MOZAMBIQUE</option>
                        <option>MYANMAR</option>
                        <option>NAMIBIA</option>
                        <option>NAURU</option>
                        <option>NEPAL</option>
                        <option>NETHERLANDS</option>
                        <option>NETHERLANDS ANTILLES</option>
                        <option>NEW CALEDONIA</option>
                        <option>NEW ZEALAND</option>
                        <option>NICARAGUA</option>
                        <option>NIGER</option>
                        <option>NIGERIA</option>
                        <option>NIUE</option>
                        <option>NORFOLK ISLAND</option>
                        <option>NORTH KOREA</option>
                        <option>NORTHERN MARIANA ISLANDS</option>
                        <option>NORWAY</option>
                        <option>OMAN</option>
                        <option>PAKISTAN</option>
                        <option>PALAU</option>
                        <option>PANAMA</option>
                        <option>PAPUA NEW GUINEA</option>
                        <option>PARAGUAY</option>
                        <option >PERU</option>
                        <option>PHILIPPINES</option>
                        <option>PITCAIRN ISLAND</option>
                        <option>POLAND</option>
                        <option>PORTUGAL</option>
                        <option>PUERTO RICO</option>
                        <option>QATAR</option>
                        <option>REUNION</option>
                        <option>ROMANIA</option>
                        <option>RUSSIAN FEDERATION</option>
                        <option>RWANDA</option>
                        <option>SAINT HELENA</option>
                        <option>SAINT KITTS-NEVIS-ANGUILLA</option>
                        <option>SAINT LUCIA</option>
                        <option>SAINT PIERRE AND MIQUELON</option>
                        <option>SAINT VINCENT AND THE GRENADINES</option>
                        <option>SAMOA</option>
                        <option>SAN MARINO</option>
                        <option>SAO TOME AND PRINCIPE</option>
                        <option>SAUDI ARABIA</option>
                        <option>SENEGAL</option>
                        <option>SEYCHELLES</option>
                        <option>SIERRA LEONE</option>
                        <option>SINGAPORE</option>
                        <option>SLOVAKIA</option>
                        <option>SLOVENIA</option>
                        <option>SOLOMON ISLANDS</option>
                        <option>SOMALIA</option>
                        <option>SOUTH AFRICA</option>
                        <option>SOUTH GEORGIA AND THE SOUTH SANDWICH ISL</option>
                        <option>SPAIN</option>
                        <option>SRI LANKA</option>
                        <option>SUDAN</option>
                        <option>SURINAME</option>
                        <option>SVALBARD AND JAN MAYEN ISLANDS</option>
                        <option>SWAZILAND</option>
                        <option>SWEDEN</option>
                        <option>SWITZERLAND</option>
                        <option>SYRIAN ARAB REPUBLIC</option>
                        <option>TAIWAN</option>
                        <option>TAJIKISTAN</option>
                        <option>TANZANIA, UNITED REPUBLIC OF</option>
                        <option>THAILAND</option>
                        <option>TOGO</option>
                        <option>TOKELAU</option>
                        <option>TONGA</option>
                        <option>TRINIDAD AND TOBAGO</option>
                        <option>TUNISIA</option>
                        <option>TURKEY</option>
                        <option>TURKMENISTAN</option>
                        <option>TURKS AND CAICOS ISLANDS</option>
                        <option>TUVALU</option>
                        <option>UGANDA</option>
                        <option>UKRAINE</option>
                        <option>UNITED ARAB EMIRATES</option>
                        <option>UNITED KINGDOM</option>
                        <option>UNITED STATES</option>
                        <option>UNITED STATES MINOR OUTLYING ISLANDS</option>
                        <option>URUGUAY</option>
                        <option>UZBEKISTAN</option>
                        <option>VANUATU</option>
                        <option>VATICAN CITY STATE</option>
                        <option>VENEZUELA</option>
                        <option>VIETNAM</option>
                        <option>VIRGIN ISLANDS, BRITISH</option>
                        <option>VIRGIN ISLANDS, U.S</option>
                        <option>WAKE ISLAND</option>
                        <option>WALLIS AND FUTUNA ISLANDS</option>
                        <option>WESTERN SAHARA</option>
                        <option>YEMEN</option>
                        <option>YUGOSLAVIA</option>
                        <option>ZAMBIA</option>
                        <option>ZIMBABWE</option>
                    </select>
                </div>

                <LabelledInput name="BirthYear" label="Year of Birth"
                    value={this.state.BirthYear} handleChange={this.handleChange}
                    className="Question FullLabel SpaceAtTop"
                    pattern="[1][9][0-9]{2}|[2][0][0-9]{2}" />  

                <button className="ExternalLink FullWidth"
                    onClick={this.handleSave} 
                    type="button">Save</button>

            </section>
        );
    }
}

class AboutMe extends React.Component<ProfileState, AboutMeState> {

    constructor(props: ProfileState) {
        super(props);

        this.state = {
            FirstName: props.firstName,
            LastName: props.lastName,
            City: props.city,
            Country: props.country,
            Expertise: props.expertise,
            Vocation: props.vocation,
            Experience: props.experience,
            BirthYear: props.birthYear
        };

        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let name = event.currentTarget.name;
        let value = event.currentTarget.value;

        this.setState(prevState => { return {
            ...prevState,
            [name]: value
        }});
    }

    handleSave = () => {
        authorizedFetchWithBody(
            "/User/Profile",
            "POST",
            JSON.stringify({
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                City: this.state.City,
                Country: this.state.Country,
                Expertise: Number.parseInt(this.state.Expertise),
                Vocation: Number.parseInt(this.state.Vocation),
                Experience: Number.parseInt(this.state.Experience),
                BirthYear: Number.parseInt(this.state.BirthYear)
            }),
            "application/json"
        )
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    render() {
        return (
            <section>
                
                <div>
                    <p className="Question">Expertise</p>

                    <div className="RadioGroup Expertise">
                        <RadioButton name="Expertise" label="Beginner"
                            value="1" current={this.state.Expertise} onChange={this.handleChange} />
                        <RadioButton name="Expertise" label="Intermediate"
                            value="2" current={this.state.Expertise} onChange={this.handleChange} />
                        <RadioButton  name="Expertise" label="Expert"
                            value="3" current={this.state.Expertise} onChange={this.handleChange} />
                    </div>
                </div> 

                <div>
                    <p className="Question">Vocation</p>

                    <div className="RadioGroup Vocation">
                        <RadioButton name="Vocation" label="Producer"
                            value="1" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="Engineer"
                            value="2" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="Musician"
                            value="3" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="Songwriter"
                            value="4" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="Beat Maker"
                            value="5" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="DJ"
                            value="6" current={this.state.Vocation} onChange={this.handleChange} />
                        <RadioButton name="Vocation" label="Educator"
                            value="7" current={this.state.Vocation} onChange={this.handleChange} />
                    </div>
                </div>

                <div>
                    <p className="Question">Experience</p>

                    <div className="RadioGroup Group--no-wrap">
                        <RadioButton  name="Experience" label="1-5"
                            value="1" current={this.state.Experience} onChange={this.handleChange} />
                        <RadioButton  name="Experience" label="6-10"
                            value="2" current={this.state.Experience} onChange={this.handleChange} />
                        <RadioButton  name="Experience" label="11-20"
                            value="3" current={this.state.Experience} onChange={this.handleChange} />
                        <RadioButton  name="Experience" label="21+"
                            value="4" current={this.state.Experience} onChange={this.handleChange} />
                    </div>
                </div>

                <button className="ExternalLink FullWidth"
                    onClick={this.handleSave} 
                    type="button">Save</button>
            </section>
        );
    }
}

export class Account extends React.Component<any, ProfileState> {

    constructor(props: any) {
        super(props);

        this.state = {
            canRender: false,
            contests: [],
            firstName: "",
            lastName: "",
            city: "",
            country: "",
            expertise: "",
            vocation: "",
            experience: "",
            birthYear: ""
        };
    }

    componentDidMount() {
        authorizedFetch('/User/Profile', 'GET')
        .then((response) => response.json())
        .then((data) => this.setState({
            canRender: true,
            contests: data.contestsEntered,
            firstName: data.firstName ?? "",
            lastName: data.lastName ?? "",
            city: data.city ?? "",
            country: data.country ?? "",
            expertise: (data.expertise ?? "").toString(),
            vocation: (data.vocation ?? "").toString(),
            experience: (data.experience ?? "").toString(),
            birthYear: data.birthYear ?? ""
        }))
        .catch((error) => alert(error));
    }

    /*
        <h1>Song Contests Entered</h1>

        <ul>

            {this.state.contests.map((contest, i) => 
                <li key={i}>{contest}</li>)
            }

        </ul>
    */

    render() {

        let fragment = null;

        switch (this.props.fragment) {
            case "competitions": {
                fragment = <Contests contests={this.state.contests}/>;
                break;
            }
            case "aboutme": {
                fragment = <AboutMe 
                        canRender={this.state.canRender}
                        contests={this.state.contests}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        city={this.state.city}
                        country={this.state.country}
                        expertise={this.state.expertise}
                        vocation={this.state.vocation}
                        experience={this.state.experience}
                        birthYear={this.state.birthYear}
                     />;
                break;
            }
            case "general":
            default: {
                fragment = <General 
                        canRender={this.state.canRender}
                        contests={this.state.contests}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        city={this.state.city}
                        country={this.state.country}
                        expertise={this.state.expertise}
                        vocation={this.state.vocation}
                        experience={this.state.experience}
                        birthYear={this.state.birthYear}
                    />;
                break;
            }
        }

        return (
            <section>

                <div className='Submenu'>
                    <SubMenuItem header="General" 
                        main="profile" 
                        route="general" 
                        isActive={this.props.fragment === "general"}/>
                    <SubMenuItem header="About Me" 
                        main="profile" 
                        route="aboutme" 
                        isActive={this.props.fragment === "aboutme"}/>
                    <SubMenuItem header="Survey" 
                        main="profile" 
                        route="competitions" 
                        isActive={this.props.fragment === "competitions"}/>
                </div>

                {this.state.canRender && fragment}
                
            </section>
        );
    }
}