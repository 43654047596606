import React from 'react';

type ActivateProps = {
    code: string;
}

export class Activate extends React.Component<ActivateProps> {

    constructor(props: ActivateProps) {
        super(props);

        fetch(process.env.REACT_APP_API_URL + '/User/Activate', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                Code: props.code
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error("" + response.status);
            }

            window.location.href = "/app/#/login";
        })
        .catch((error) => {
            alert(error);
        });
    }

    render() {
        return (
            <section>
                <h1>Activating your account</h1>
            </section>
        );
    }
}