import React from 'react';
import {SurveyStep, LabelledInput} from './FormElements';
import { authorizedFetch, authorizedFetchWithBody } from '../../Fetch';

interface Contest {
    id: number;
    event: string;
    deadline: Date;
}

interface SongCompetitionState {
    started: boolean;
    submitted: boolean;
    currentPage: number;
    agreedToDetails: boolean;
    agreedToRules: boolean;
    display: "Contest" | "Details" | "Rules";
    locations: Contest[];
    selectedContest: number;

    /*
    song entry specific fields
    */
    //from song page
    songTitle: string;
    artistName: string;
    //files use refs not strings - song: string; //TODO: file?
    //files use refs not strings - headshot: string; //TODO: file?
    bio: string;
    //from address page
    streetAddress: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    country: string;
}

export class SongCompetition extends React.Component<any, SongCompetitionState> {
    private maxPage = 3;
    private songFileRef: React.RefObject<HTMLInputElement>;
    private headshotFileRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.state = {
            started: false,
            submitted: false,
            currentPage: 1,
            agreedToDetails: false,
            agreedToRules: false,
            display: "Contest",
            locations: [],
            selectedContest: 0,
            songTitle: "",
            artistName: "",
            bio: "",
            streetAddress: "",
            addressLine2: "",
            city: "",
            county: "",
            postcode: "",
            country: ""
        };

        this.songFileRef = React.createRef<HTMLInputElement>();
        this.headshotFileRef = React.createRef<HTMLInputElement>();

        this.handleStart = this.handleStart.bind(this);
        this.previousCompetitionPage = this.previousCompetitionPage.bind(this);
        this.nextCompetitionPage = this.nextCompetitionPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        authorizedFetch(
            '/SongCompetition', "GET"
        )
        .then((response) => response.json())
        .then((data) => {

            if (data.length === 0) {
                return;
            }
            else {
                this.setState({
                    locations: data.map((d: any) => ({
                        id: d.id,
                        event: d.event,
                        deadline: new Date(d.deadline)
                    } as Contest)).filter((d: Contest) => d.deadline > new Date()),
                    selectedContest: data[0].id
                });
            }
        })
        .catch((error) => alert(error));

        let viewer = document.querySelector(".Viewer") as HTMLElement;
        let fragment = document.querySelector(".Fragment") as HTMLElement;

        if (viewer && fragment) {
            let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			let mainHeight = height - 70 - 64;
            mainHeight = mainHeight - 30 - 74;
            fragment.style.gridTemplateRows = mainHeight + 'px 32px 42px'
        }
    }

    submitSong = () => {
        for (let i = 0; i < this.maxPage; i++) {
            if (!this.getCanProgress(i + 1)) {
                return;
            }
        }

        if (!this.songFileRef 
                || !this.songFileRef.current
                || !this.songFileRef.current.files
                || !this.songFileRef.current.files[0]) {
            return;
        }

        if (!this.headshotFileRef 
            || !this.headshotFileRef.current
            || !this.headshotFileRef.current.files
            || !this.headshotFileRef.current.files[0]) {
        return;
    }

        let data = new FormData();
        data.append("SongTitle", this.state.songTitle);
        data.append("ArtistName", this.state.artistName);
        data.append("Bio", this.state.bio);
        data.append("StreetAddress", this.state.streetAddress);
        data.append("AddressLine2", this.state.addressLine2);
        data.append("City", this.state.city);
        data.append("County", this.state.county);
        data.append("Postcode", this.state.postcode);
        data.append("Country", this.state.country);
        data.append("Song", this.songFileRef.current.files[0]);
        data.append("Headshot", this.headshotFileRef.current.files[0]);

        //TODO: this needs to pass auth header

        authorizedFetchWithBody(
            "/SongCompetition/" + this.state.selectedContest, 
            "POST",
            data
        )
        .then((response) => {
            //console.log(response);
            this.setState({
                submitted: true
            });
        })
        .catch((error) => {
            alert(error);
            console.log(error);
        });

    };

    showDetailsPopup = () => {
        this.setState({
            display: "Details"
        });
    };

    showRulesPopup = () => {
        this.setState({
            display: "Rules"
        });
    };

    agreeToDetails = () => {
        this.setState({
            agreedToDetails: true,
            display: "Contest"
        });
    };

    declineDetails = () => {
        this.setState({
            agreedToDetails: false,
            display: "Contest"
        });
    };

    agreeToRules = () => {
        this.setState({
            agreedToRules: true,
            display: "Contest"
        });
    };

    declineRules = () => {
        this.setState({
            agreedToRules: false,
            display: "Contest"
        });
    };

    handleSelectContest = (event: React.FormEvent<HTMLSelectElement>) => {
        this.setState({
            selectedContest: Number(event.currentTarget.value)
        });
    };

    previousCompetitionPage() {
        if (this.state.currentPage > 1) {
            this.setState({currentPage: this.state.currentPage - 1});
        }
    }

    nextCompetitionPage() {
        if (this.state.currentPage < this.maxPage) {
            this.setState({currentPage: this.state.currentPage + 1});
        }
    }

    handleStart() {
        this.setState({started: true});
    }

    handleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let name = event.currentTarget.name;
        let value = event.currentTarget.value;

        this.setState(prevState => { return {
            ...prevState,
            [name]: value
        }});
    }

    handleDummyFiles = (event: React.FormEvent<HTMLInputElement>) => {
        this.forceUpdate();
    };

    renderThankYou() {
        return (
            <section className="Fragment">
                <div className="SpaceAtTop">
                    <h1>Thank you</h1>          

                    <p>Your song has been submitted, but won't be formally entered into the contest
                        until the entry fee is paid.</p>

                    <p>Please check your email for an invoice from Stripe to complete payment.</p>
                </div>
            </section>
        );
    }

    renderNoAvailableCompetitions() {
        return (
            <section className="Fragment">
                <div className="SpaceAtTop">
                    <h1>No available competitions</h1>          

                    <p>We're sorry, there are no song competitions at the moment. Please check back later</p>

                </div>
            </section>
        );
    }

    render() {
        if (this.state.submitted) {
            return this.renderThankYou();
        }

        if (this.state.started) {
            return this.renderCompetition();
        }

        if (this.state.locations.length === 0) {
            return this.renderNoAvailableCompetitions();
        }

        //todo: filter available locations by date
        let detailsClass = "ExternalLink AgreementDisplayer";
        let rulesClass = "ExternalLink AgreementDisplayer";

        if (!this.state.agreedToDetails) {
            detailsClass += " NotAccepted";
        }
        else {
            detailsClass += " Accepted"
        }

        if (!this.state.agreedToRules) {
            rulesClass += " NotAccepted";
        }
        else {
            rulesClass += " Accepted";
        }

        let innerContent = null;
        let enterSongButton = null;

        if (this.state.agreedToDetails && this.state.agreedToRules) {
            enterSongButton = <button className="EnterSongButton ExternalLink FullWidth"
                type="button"
                onClick={this.handleStart}
                >Enter</button>;
        }
        else {
            enterSongButton = <button className="EnterSongButton ExternalLink FullWidth NotAccepted"
                type="button"
                >Enter</button>
        }

        let self = this;

        switch (this.state.display) {
            case "Contest": {
                innerContent = <div>

                        <div className="SongLocation">
                            <p>Location</p>
                            <p>Deadline</p>

                            <select value={this.state.selectedContest} onChange={this.handleSelectContest}>
                                {this.state.locations.map((l, i) => <option key={i} value={l.id}>{l.event}</option>)}
                            </select>

                            <p>{this.state.locations.find(l => l.id === self.state.selectedContest)
                                ?.deadline.toLocaleDateString(undefined, {
                                    month: 'short', day: 'numeric', year: 'numeric'
                                })}</p>
                        </div>

                        <div className="SongTerms">
                            <p>I understand and agree to:</p>

                            <div className="AgreeBox">
                                <button className={detailsClass} type="button"
                                    onClick={this.showDetailsPopup}
                                    >Contest Details</button>
                                <button className={rulesClass} type="button"
                                    onClick={this.showRulesPopup}
                                    >Contest Rules</button>
                            </div>
                        </div>

                        {enterSongButton}
                    </div>;
                break;   
            }
            case "Details": {
                innerContent = <div className="AgreementPopup">
                        <h1>Competition Details</h1>

                        <ol>
                            <li>IMSTA Employees, Partners, Sponsors, their families, subsidiaries, and affiliates are not eligible to enter IMSTA Song-Witting competition.</li>
                            <li>Each song submitted to the IMSTA Song-Writing Competition is $20.00 usd.</li>
                            <li>Songs must be submitted through imsta.org or this App</li>
                            <li>Each song entry must be Entrant's original creation.</li>
                            <li>Entries may have co-writer[s], but one name must be designated per entry</li>
                            <li>Songs should not exceed 5 minutes in length (5:00).</li>
                            <li>Songs will be judged based on melody, composition, originality and lyrics (where applicable).</li>
                            <li>Prizes will be awarded to the Entrant even with multiple named authors; division of prize is responsibility of named - winner.</li>
                            <li>Entry files must be uploaded as an MP3. IMSTA is not responsible for any corrupt files you may submit. Any and all corrupt files will be deleted without notification to you.</li>
                            <li>All submissions to this Competition must be fully submitted by specified deadline on imsta.org.</li>
                            <li>All entries are final and irrevocable. Once you have submitted an entry for this Competition you may not withdraw the entry or request to change the category, genre or other division you chose for the entry.</li>
                            <li>By entering the IMSTA Song-Writing Competition - you hereby give consent to have your song and/or song link posted to the IMSTA website, social media platforms, or any equivalent streaming/distribution service where your works are available, YouTube or any other online service for promotional purposes only.</li>
                            <li>Winner of the grand prize must be eligible and able to travel to receive prize. Grand prize winner will be notified by email at the address provided, and must sign and return an affidavit of eligibility/publicity release within ten (10) days after the date on such notification. The affidavit will state that the winner's song is an original work and that the winner holds all rights to the song or is a cover of a licensed song and is identified as such in the submission. Failure to sign and return this affidavit within ten (10) days, or the provision of false/inaccurate information therein, will result in immediate disqualification and an alternate winner will be selected. Affidavits of winners under eighteen (18) years of age at the time of award must be countersigned by the winner's parent or legal guardian. Affidavits are subject to verification by IMSTA.</li>
                        </ol>

                        <div className="BasicFlex AgreeYesNoBox">
                            <button className="ExternalLink AgreeOption" type="button"
                                onClick={this.agreeToDetails}>Agree</button>
                            <button className="ExternalLink AgreeOption" type="button"
                                onClick={this.declineDetails}>Decline</button>
                        </div>
                    </div>;
                break;
            }
            case "Rules": {
                innerContent = <div className="AgreementPopup">
                        <h1>Competition Rules</h1>

                        <ol>
                            <li>Prize winners may be asked to prove that the composition was created with legal, purchased software. Songwriters using illegal software are not eligible to win the IMSTA Song-Writing Competition.</li>
                            <li>Submitted finalists are required to be present for judging at IMSTA FESTA.</li>
                            <li>Entrants must be at least 16 years of age to enter this Competition.</li>
                            <li>Entrants must provide true, accurate, current and complete contact and other information.</li>
                            <li>You will be disqualified for false registration, making false claims, misrepresentation or any other conduct, action or activity that IMSTA, in its sole discretion, deems to be "cheating."</li>
                            <li>Each song submitted must consist entirely of the Entrant’s original work. You will be disqualified for submitting any material for which you either do not solely own all worldwide copyright rights or have not obtained all necessary licenses, in proper form.</li>
                            <li>Collaborative work among co-Entrants will be accepted, but all prizes will be awarded to the Entrants whose name is on the submission form. Division of prizes among co-Entrants is the sole responsibility of winners and IMSTA shall have no liability for co-Entrants failure to divide prizes.</li>
                            <li>If an entry created by multiple songwriters wins the Grand Prize, only one songwriter may claim the complete Grand Prize. IMSTA is not responsible for selecting the single recipient of this prize.</li>
                            <li>Closing and announcement dates for this Competition may change without notice to you. Such changes will be posted on imsta.org.</li>
                            <li>IMSTA may change these rules at any time, at IMSTA’s sole discretion, without prior notice to you.</li>
                            <li>Entering this Competition does not grant you any right or license to use any IMSTA material (i.e. logos, images, text, etc.), trademarks or other intellectual property of IMSTA for any purpose, without first obtaining IMSTA’s express written consent.</li>
                            <li>Employees of IMSTA, their sponsors, advertising agencies, affiliates, agents, representatives, and the families or households of the above are ineligible to enter or win any IMSTA Competitions.</li>
                            <li>The selection of winners by IMSTA and the judges are final and cannot be appealed.</li>
                            <li>All Entrants acknowledge, as a condition of entry, that IMSTA has the right to publicize and broadcast any winner's name, character, likeness, voice and all other matters incidental thereto.</li>
                            <li>This Competition is void where prohibited.</li>
                            <li>By entering this contest you hereby give consent to the recording, broadcast and promotion(s) of the reproduction(s) of the voice and music as part of the IMSTA Song-Writing Competition. You also understand and agree to receive no compensation for appearances and participation in the IMSTA Song-Writing Competition or promotion(s) of your works.</li>
                            <li>By entering the IMSTA Song-Writing Competition - you hereby give consent to have your song and/or song link posted to the IMSTA website, social media platforms or any equivalent streaming/distribution service where your works are available, YouTube or any other online service for promotional purposes only.</li>
                            <li>If you are a Finalist or a Wildcard, IMSTA reserves the right to use your name and likeness for promotional purposes.</li>
                            <li>Artist's name and likeness may be used in advertising and promotional material for the IMSTA Song-Writing Competition, but not as an endorsement of any product or service.</li>
                            <li>All rights in and to Artist's performance submitted to the IMSTA Song-writing Competition herein shall remain the sole property of the Artist and the rights granted herein are for broadcast and promotion only.</li>
                        </ol>

                        <div className="BasicFlex AgreeYesNoBox">
                            <button className="ExternalLink AgreeOption" type="button"
                                onClick={this.agreeToRules}>Agree</button>
                            <button className="ExternalLink AgreeOption" type="button"
                                onClick={this.declineRules}>Decline</button>
                        </div>
                    </div>;
                break;
            }
        }

        return (
            <section className="Fragment">
                {innerContent}                
            </section>
        );
    }

    renderCompetition() {
        switch (this.state.currentPage) {
            case 1: {
                return this.renderSongPage();
            }
            case 2: {
                return this.renderAddressPage();
            }
            case 3: {
                return this.renderSubmitPage();
            }
            default: {
                return this.renderSongPage();
            }
        }
    }

    renderPageNavigation(canProgress: boolean) {
        let backButtonClass = "Survey-Navigation-Back";
        
        if (this.state.currentPage <= 1) {
            backButtonClass += " hidden";
        }

        let nextButton = null;

        if (canProgress) {
            nextButton = <button className="Survey-Navigation-Next"
                onClick={this.nextCompetitionPage}
                type="button">Next</button>;
        }
        else {
            nextButton = <button className="Survey-Navigation-Next Survey-Navigation-Next--disabled"
                type="button">Next</button>;
        }

        return (
            <div className="Survey-Navigation">
                    <button className={backButtonClass}
                        onClick={this.previousCompetitionPage}
                        type="button">Back</button>
                    
                    {nextButton}                    
                </div>
        );
    }

    renderPageProgress() {

        let steps = [];

        for (let i = 0; i < this.maxPage; i++) {
            let page = i + 1;
            let canProgress = this.getCanProgress(page);
            steps.push(<SurveyStep key={i} step={page} active={this.state.currentPage} completed={canProgress} />)
        }

        return (
            <div className="Survey-Feedback">

                <div className="SurveyProgress">
                    <hr className="Progress-Line" />
                    {steps}
                </div>
            </div>
        );
    }

    renderSubmitSurvey(canProgress: boolean) {

        let nextButton = null;

        if (canProgress) {
            nextButton = <button className="Survey-Navigation-Next"
                onClick={this.submitSong}
                type="button">Submit</button>
        }
        else {
            nextButton = <button className="Survey-Navigation-Next Survey-Navigation-Next--disabled"
                type="button">Submit</button>
        }

        return (
            <div className="Survey-Navigation">
                <button className="Survey-Navigation-Back"
                    onClick={this.previousCompetitionPage}
                    type="button">Back</button>

                {nextButton}
            </div>
        );
    }

    renderSongPage() {
        let canProgress = this.getCanProgress(1);

        return (
            <section className="Fragment Competition">
                <div className="SpaceAtTop">
                    <h1>Song Details</h1>

                    <div className="LabelledInputGroup ColumnGroup">
                        
                        <LabelledInput name="songTitle" label="Song Title"
                            value={this.state.songTitle} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop" />

                        <LabelledInput name="artistName" label="Artist Name"
                            value={this.state.artistName} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop" />

                        <label className="Question FullLabel SpaceAtTop">Artist Bio</label>
                        <textarea name="bio" className="Fix" rows={8}
                            value={this.state.bio} onChange={this.handleChange}></textarea>
                    </div>

                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderAddressPage() {
        let canProgress = this.getCanProgress(2);

        return (
            <section className="Fragment Competition">

                <div className="SpaceAtTop">
                    <h1>Artist Address</h1>

                    <div className="LabelledInputGroup ColumnGroup">

                        <LabelledInput name="streetAddress" label="Street Address"
                            value={this.state.streetAddress} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop--small" />

                        <LabelledInput name="addressLine2" label="Address Line 2"
                            value={this.state.addressLine2} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop--small" />

                        <LabelledInput name="city" label="City"
                            value={this.state.city} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop--small" />

                        <LabelledInput name="county" label="County"
                            value={this.state.county} handleChange={this.handleChange}
                            className="Question FullLabel SpaceAtTop--small" />

                        <div className="PostAndCountryPicker">
                            <div>
                                <label className="Question FullLabel SpaceAtTop--small">Postcode</label>
                                <input name="postcode"
                                    value={this.state.postcode} onChange={this.handleChange} />
                            </div>
                            <div>
                                <label className="Question FullLabel SpaceAtTop--small">Country</label>
                                <select name="country" className="FullHeightDropdown"
                                    value={this.state.country} onChange={this.handleChange} >
                                    <option></option>
                                    <option>AFGHANISTAN</option>
                                    <option>ALBANIA</option>
                                    <option>ALGERIA</option>
                                    <option>AMERICAN SAMOA</option>
                                    <option>ANDORRA</option>
                                    <option>ANGOLA</option>
                                    <option>ANGUILLA</option>
                                    <option>ANTARCTICA</option>
                                    <option>ANTIGUA AND BARBUDA</option>
                                    <option>ARGENTINA</option>
                                    <option>ARMENIA</option>
                                    <option>ARUBA</option>
                                    <option>AUSTRALIA</option>
                                    <option>AUSTRIA</option>
                                    <option>AZERBAIJAN</option>
                                    <option>BAHAMAS</option>
                                    <option>BAHRAIN</option>
                                    <option>BANGLADESH</option>
                                    <option>BARBADOS</option>
                                    <option>BELARUS</option>
                                    <option>BELGIUM</option>
                                    <option>BELIZE</option>
                                    <option>BENIN</option>
                                    <option>BERMUDA</option>
                                    <option>BHUTAN</option>
                                    <option>BOLIVIA</option>
                                    <option>BOSNIA AND HERZEGOVINA</option>
                                    <option>BOTSWANA</option>
                                    <option>BOUVET ISLAND</option>
                                    <option>BRAZIL</option>
                                    <option>BRITISH INDIAN OCEAN TERRITORY</option>
                                    <option>BRUNEI DARUSSALAM</option>
                                    <option>BULGARIA</option>
                                    <option>BURKINA FASO</option>
                                    <option>BURUNDI</option>
                                    <option>CAMBODIA</option>
                                    <option>CAMEROON</option>
                                    <option>CANADA</option>
                                    <option>CAPE VERDE</option>
                                    <option>CAYMAN ISLANDS</option>
                                    <option>CENTRAL AFRICAN REPUBLIC</option>
                                    <option>CHAD</option>
                                    <option>CHILE</option>
                                    <option>CHINA</option>
                                    <option>CHRISTMAS ISLAND</option>
                                    <option>COCOS (KEELING) ISLANDS</option>
                                    <option>COLOMBIA</option>
                                    <option>COMOROS</option>
                                    <option>CONGO</option>
                                    <option>CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                                    <option>COOK ISLANDS</option>
                                    <option>COSTA RICA</option>
                                    <option>CROATIA</option>
                                    <option>CUBA</option>
                                    <option>CYPRUS</option>
                                    <option>CZECH REPUBLIC</option>
                                    <option>DENMARK</option>
                                    <option>DJIBOUTI</option>
                                    <option>DOMINICA</option>
                                    <option>DOMINICAN REPUBLIC</option>
                                    <option>EAST TIMOR</option>
                                    <option>ECUADOR</option>
                                    <option>EGYPT</option>
                                    <option>EL SALVADOR</option>
                                    <option>EQUATORIAL GUINEA</option>
                                    <option>ERITREA</option>
                                    <option>ESTONIA</option>
                                    <option>ETHIOPIA</option>
                                    <option>FALKLAND ISLANDS (MALVINAS)</option>
                                    <option>FAROE ISLANDS</option>
                                    <option>FIJI</option>
                                    <option>FINLAND</option>
                                    <option>FRANCE</option>
                                    <option>FRENCH GUIANA</option>
                                    <option>FRENCH POLYNESIA</option>
                                    <option>FRENCH SOUTHERN TERRITORIES</option>
                                    <option>GABON</option>
                                    <option>GAMBIA</option>
                                    <option>GEORGIA</option>
                                    <option>GERMANY</option>
                                    <option>GHANA</option>
                                    <option>GIBRALTAR</option>
                                    <option>GREECE</option>
                                    <option>GREENLAND</option>
                                    <option>GRENADA</option>
                                    <option>GUADELOUPE</option>
                                    <option>GUAM</option>
                                    <option>GUATEMALA</option>
                                    <option>GUINEA</option>
                                    <option>GUINEA-BISSAU</option>
                                    <option>GUYANA</option>
                                    <option>HAITI</option>
                                    <option>HEARD AND MCDONALD ISLANDS</option>
                                    <option>HONDURAS</option>
                                    <option>HONG KONG</option>
                                    <option>HUNGARY</option>
                                    <option>ICELAND</option>
                                    <option>INDIA</option>
                                    <option>INDONESIA</option>
                                    <option>IRAN</option>
                                    <option>IRAQ</option>
                                    <option>IRELAND</option>
                                    <option>ISRAEL</option>
                                    <option>ITALY</option>
                                    <option>IVORY COAST</option>
                                    <option>JAMAICA</option>
                                    <option>JAPAN</option>
                                    <option>JORDAN</option>
                                    <option>KAZAKHSTAN</option>
                                    <option>KENYA</option>
                                    <option>KIRIBATI</option>
                                    <option>KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF</option>
                                    <option>KOREA,REPUBLIC OF</option>
                                    <option>KUWAIT</option>
                                    <option>KYRGYZSTAN</option>
                                    <option>LAO PEOPLES DEMOCRATIC REPUBLIC</option>
                                    <option>LATVIA</option>
                                    <option>LEBANON</option>
                                    <option>LESOTHO</option>
                                    <option>LIBERIA</option>
                                    <option>LIBYAN ARAB JAMAHIRIYA</option>
                                    <option>LIECHTENSTEIN</option>
                                    <option>LITHUANIA</option>
                                    <option>LUXEMBOURG</option>
                                    <option>MACAO</option>
                                    <option>MACEDONIA,THE FORMER YUGOSLAV REPUBLIC</option>
                                    <option>MADAGASCAR</option>
                                    <option>MALAWI</option>
                                    <option>MALAYSIA</option>
                                    <option>MALDIVES</option>
                                    <option>MALI</option>
                                    <option>MALTA</option>
                                    <option>MARSHALL ISLANDS</option>
                                    <option>MARTINIQUE</option>
                                    <option>MAURITANIA</option>
                                    <option>MAURITIUS</option>
                                    <option>MEXICO</option>
                                    <option>MICRONESIA, FEDERATED STATES OF</option>
                                    <option>MOLDOVA, REPUBLIC OF</option>
                                    <option>MONACO</option>
                                    <option>MONGOLIA</option>
                                    <option>MONTSERRAT</option>
                                    <option>MOROCCO</option>
                                    <option>MOZAMBIQUE</option>
                                    <option>MYANMAR</option>
                                    <option>NAMIBIA</option>
                                    <option>NAURU</option>
                                    <option>NEPAL</option>
                                    <option>NETHERLANDS</option>
                                    <option>NETHERLANDS ANTILLES</option>
                                    <option>NEW CALEDONIA</option>
                                    <option>NEW ZEALAND</option>
                                    <option>NICARAGUA</option>
                                    <option>NIGER</option>
                                    <option>NIGERIA</option>
                                    <option>NIUE</option>
                                    <option>NORFOLK ISLAND</option>
                                    <option>NORTH KOREA</option>
                                    <option>NORTHERN MARIANA ISLANDS</option>
                                    <option>NORWAY</option>
                                    <option>OMAN</option>
                                    <option>PAKISTAN</option>
                                    <option>PALAU</option>
                                    <option>PANAMA</option>
                                    <option>PAPUA NEW GUINEA</option>
                                    <option>PARAGUAY</option>
                                    <option >PERU</option>
                                    <option>PHILIPPINES</option>
                                    <option>PITCAIRN ISLAND</option>
                                    <option>POLAND</option>
                                    <option>PORTUGAL</option>
                                    <option>PUERTO RICO</option>
                                    <option>QATAR</option>
                                    <option>REUNION</option>
                                    <option>ROMANIA</option>
                                    <option>RUSSIAN FEDERATION</option>
                                    <option>RWANDA</option>
                                    <option>SAINT HELENA</option>
                                    <option>SAINT KITTS-NEVIS-ANGUILLA</option>
                                    <option>SAINT LUCIA</option>
                                    <option>SAINT PIERRE AND MIQUELON</option>
                                    <option>SAINT VINCENT AND THE GRENADINES</option>
                                    <option>SAMOA</option>
                                    <option>SAN MARINO</option>
                                    <option>SAO TOME AND PRINCIPE</option>
                                    <option>SAUDI ARABIA</option>
                                    <option>SENEGAL</option>
                                    <option>SEYCHELLES</option>
                                    <option>SIERRA LEONE</option>
                                    <option>SINGAPORE</option>
                                    <option>SLOVAKIA</option>
                                    <option>SLOVENIA</option>
                                    <option>SOLOMON ISLANDS</option>
                                    <option>SOMALIA</option>
                                    <option>SOUTH AFRICA</option>
                                    <option>SOUTH GEORGIA AND THE SOUTH SANDWICH ISL</option>
                                    <option>SPAIN</option>
                                    <option>SRI LANKA</option>
                                    <option>SUDAN</option>
                                    <option>SURINAME</option>
                                    <option>SVALBARD AND JAN MAYEN ISLANDS</option>
                                    <option>SWAZILAND</option>
                                    <option>SWEDEN</option>
                                    <option>SWITZERLAND</option>
                                    <option>SYRIAN ARAB REPUBLIC</option>
                                    <option>TAIWAN</option>
                                    <option>TAJIKISTAN</option>
                                    <option>TANZANIA, UNITED REPUBLIC OF</option>
                                    <option>THAILAND</option>
                                    <option>TOGO</option>
                                    <option>TOKELAU</option>
                                    <option>TONGA</option>
                                    <option>TRINIDAD AND TOBAGO</option>
                                    <option>TUNISIA</option>
                                    <option>TURKEY</option>
                                    <option>TURKMENISTAN</option>
                                    <option>TURKS AND CAICOS ISLANDS</option>
                                    <option>TUVALU</option>
                                    <option>UGANDA</option>
                                    <option>UKRAINE</option>
                                    <option>UNITED ARAB EMIRATES</option>
                                    <option>UNITED KINGDOM</option>
                                    <option>UNITED STATES</option>
                                    <option>UNITED STATES MINOR OUTLYING ISLANDS</option>
                                    <option>URUGUAY</option>
                                    <option>UZBEKISTAN</option>
                                    <option>VANUATU</option>
                                    <option>VATICAN CITY STATE</option>
                                    <option>VENEZUELA</option>
                                    <option>VIETNAM</option>
                                    <option>VIRGIN ISLANDS, BRITISH</option>
                                    <option>VIRGIN ISLANDS, U.S</option>
                                    <option>WAKE ISLAND</option>
                                    <option>WALLIS AND FUTUNA ISLANDS</option>
                                    <option>WESTERN SAHARA</option>
                                    <option>YEMEN</option>
                                    <option>YUGOSLAVIA</option>
                                    <option>ZAMBIA</option>
                                    <option>ZIMBABWE</option>
                                </select>
                            </div>
                        </div>

                        

                        
                    </div>

                </div>

                {this.renderPageNavigation(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    renderSubmitPage() {
        let canProgress = this.getCanProgress(3);

        return (
            <section className="Fragment Competition">
                <div className="SpaceAtTop">
                    <div className="LabelledInputGroup ColumnGroup">
                        <label className="Question FullLabel">Song Upload</label>
                        <input name="song" type="file"
                            ref={this.songFileRef} onChange={this.handleDummyFiles} />

                        <label className="Question FullLabel">Artist Headshot</label>
                        <input name="headshot" type="file"
                            ref={this.headshotFileRef} onChange={this.handleDummyFiles} />
                    </div>

                    <p>
                    By clicking Submit, I certify that the attached Song is my original work and legal software was used in its creation. I have read, accept and understand the Contest Details, Competition Rules & Regulations.
                    </p>

                    <p>
The submission fee of $20 USD must be paid to be officially entered into the contest.
                    </p>

                </div>

                {this.renderSubmitSurvey(canProgress)}
                {this.renderPageProgress()}
            </section>
        );
    }

    getCanProgress(pageIndex: number): boolean {
        switch (pageIndex) {
            case 1: {

                return !!this.state.songTitle
                    && !!this.state.artistName
                    && !!this.state.bio;
            }
            case 2: {
                return !!this.state.streetAddress
                    //&& !!this.state.addressLine2
                    && !!this.state.city
                    && !!this.state.county
                    && !!this.state.postcode
                    && !!this.state.country;
            }
            case 3: {
                if (!this.songFileRef.current || !this.headshotFileRef.current) {
                    return false;
                }

                let songRef = this.songFileRef.current;

                if (!songRef.files) {
                    return false;
                }

                let headshotRef = this.headshotFileRef.current;

                if (!headshotRef.files) {
                    return false;
                }

                return this.getCanProgress(1) 
                    && this.getCanProgress(2)
                    && !!this.songFileRef
                    && (songRef.files?.length ?? 0) > 0 
                    && !!songRef.files[0].name
                    && (headshotRef.files?.length ?? 0) > 0
                    && !!headshotRef.files[0].name
            }
            default: {
                return false;
            }
        }
    }
}	