import React from 'react';
import { SubMenuItem } from './Menu';
import './StreamViewer.css';

class VideoPlayer extends React.Component {
    render() {
        return (
            <div className="Stream Stream__Wrapper">
                <iframe id="youtube" className="Stream" title="stream"
                    ></iframe>
            </div>
        );
    }
}

class Presenter extends React.Component {
    render() {
        return (
            <div className="presenter">
                <h1 className="presenter__header">EXHIBITORS & PRESENTERS</h1>
                <div className="presenter__info">
                    <div className="presenter__topbar">
                        <img id="presenter__picture" className="presenter__picture" alt="presenter"></img>
                        <p id="presenter__name" className="presenter__name"></p>
                    </div>
                    <div>
                        <p id="presenter__description" className="presenter__description"></p>
                    </div>
                </div>
            </div>
        );
    }
}

class Stream extends React.Component {
    render() {
        return (
            <div>
                <VideoPlayer />
                <Presenter />
            </div>
        );
    }
}

type ScheduleProps = {
    eventId: string;
    startTime: number;
    currentTimeslot: number;
    timeSlotDuration: number;
    schedule: ScheduleData;
}

type ThumbnailCache = {
    purple: string[];
    red: string[];
    blue: string[];
    yellow: string[];
}

type ScheduleState = {
    thumbnails: ThumbnailCache;
}

class Schedule extends React.Component<ScheduleProps, ScheduleState> {

    constructor(props: ScheduleProps) {
        super(props);

        this.state = {
            thumbnails: {
                purple: [],
                red: [],
                blue: [],
                yellow: []
            }
        };
    }

    componentDidMount() {
        this.loadRoomSchedules(this.props.schedule);
        this.loadThumbnails(this.props.eventId);
    }

    componentDidUpdate() {
        this.loadRoomSchedules(this.props.schedule);
    }

    /*
    Room related code
    */
    loadRoomSchedules = (schedule: ScheduleData) => {
        this.setupRoom("purple-room", schedule["purple"]);
        this.setupRoom("red-room", schedule["red"]);
        this.setupRoom("blue-room", schedule["blue"]);
        this.setupRoom("yellow-room", schedule["yellow"]);
    };

    setupRoom = (name: string, schedule: any) => {
        let room = document.getElementById(name)!;
        let timeslots = room.querySelectorAll(".room__timeslot");
    
        let startTime = this.props.startTime;

        /*
        Skip to the first available timeslot
        If there are less than 3 slots left, show the last 3
        instead of advancing
        */
        let slotsToShow = [];

        for (let i = 0; i < schedule.length; i++) {
            const slot = schedule[i];

            if (slot.timeslot >= this.props.currentTimeslot) {
                slotsToShow.push(slot);

                for (let j = 1; j < 3; j++) {
                    let index = i + j;

                    if (index >= schedule.length) {
                        break;
                    }

                    slotsToShow.push(schedule[index]);
                }

                break;
            }
        }
    
        for (let i = 0; i < timeslots.length; i++) {
            let timeslot = timeslots[i];

            let time = timeslot.querySelector(".time__value")!;
            let sign = timeslot.querySelector(".time__sign")!;
            let event = timeslot.querySelector(".room__event")!;

            time.textContent = "";
            sign.textContent = "";
            event.textContent = "";
            
            if (i >= slotsToShow.length) {
                break;
            }
    
            let date = new Date(startTime + (slotsToShow[i].timeslot * this.props.timeSlotDuration * 1000));
            let hours = date.getHours();
    
            if (hours > 12) {
                sign.textContent = "PM";
                hours -= 12;
            }
            else {
                sign.textContent = "AM";
            }
    
            if (hours === 0) {
                hours = 12;
            }
    
            let minutes = date.getMinutes();
            let minuteStr = minutes.toString();
    
            if (minutes === 0) {
                minuteStr = "00";
            }
            else if (minutes < 10) {
                minuteStr = "0" + minutes;
            }
    
            time.textContent = hours + ":" + minuteStr;
            event.textContent = slotsToShow[i].name;
        }
    };

    loadThumbnails = (event: string) => {
        fetch("https://imsta.org/festa/get_thumbnails.php?event=" + event)
        .then((response) => response.json())
        .then((json) => this.storeThumbnails(json))
        .catch((exception) => console.log(exception));
    };

    storeThumbnails = (thumbnails: any) => {
        this.setState({
            thumbnails: thumbnails
        });
        this.updateThumbnails();
    };

    updateThumbnails = () => {
        this.updateThumbnail("purple");
        this.updateThumbnail("red");
        this.updateThumbnail("blue");
        this.updateThumbnail("yellow");
    };

    updateThumbnail = (roomName: string) => {
        let room = document.getElementById(roomName + "-room")!;
        let thumbnail = room.querySelector(".room__thumbnail")! as HTMLImageElement;

        let candidates: string[] = []; 
        
        switch (roomName) {
            case "purple": {
                candidates = this.state.thumbnails.purple;
                break;
            }
            case "red": {
                candidates = this.state.thumbnails.red;
                break;
            }
            case "blue": {
                candidates = this.state.thumbnails.blue;
                break;
            }
            case "yellow": {
                candidates = this.state.thumbnails.yellow;
                break;
            }
        }

        let currentThumbnail = candidates[0];

        if (this.props.currentTimeslot in candidates) {
            currentThumbnail = candidates[this.props.currentTimeslot];
        }

        thumbnail.src = "data:image/png;base64," + currentThumbnail;
    };

    render() {
        return (
            <div className="rooms">

                <a href="#/stream/purple">
                    <div id="purple-room" className="room room--current" data-room="purple">
                        <img className="room__thumbnail" width="100%" height="90" alt="purple room"></img>
                        <p className="room__name"><span className="room__icon room__icon--purple"></span>PURPLE ROOM</p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                    </div>
                </a>

                <a href="#/stream/red">
                    <div id="red-room" className="room" data-room="red">
                        <img className="room__thumbnail" width="100%" height="90" alt="red room"></img>
                        <p className="room__name"><span className="room__icon room__icon--red"></span>RED ROOM</p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                    </div>
                </a>

                <a href="#/stream/blue">
                    <div id="blue-room" className="room" data-room="blue">
                        <img className="room__thumbnail" width="100%" height="90" alt="blue room"></img>
                        <p className="room__name"><span className="room__icon room__icon--blue"></span>BLUE ROOM</p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                    </div>
                </a>

                <a href="#/stream/yellow">
                    <div id="yellow-room" className="room" data-room="yellow">
                        <img className="room__thumbnail" width="100%" height="90" alt="yellow room"></img>
                        <p className="room__name"><span className="room__icon room__icon--yellow"></span>YELLOW ROOM</p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                        <p className="room__timeslot"><span className="room__time"><span className="time__value"></span><span className="time__sign"></span></span><span className="room__event"></span></p>
                    </div>
                </a>
            </div>
        );
    }
}

type PresenterData = {
    picture: string;
    name: string;
    description: string;
}

type RoomCache = {
    [key: string]: PresenterData[];
}

type ExhibitorCache = PresenterData[];

type PresenterCache = {
    presenter: RoomCache;
    exhibitor: ExhibitorCache;
    slogan: ExhibitorCache;
}

type StreamViewerProps = {
    fragment: string;
}

type RoomSchedule = {
    timeslot: number;
    name: string;
}

type ScheduleData = {
    purple: RoomSchedule[];
    red: RoomSchedule[];
    blue: RoomSchedule[];
    yellow: RoomSchedule[];
}

type StreamViewerState = {
    //presenterCache: PresenterCache;
    //presenterInterval: number;
    currentTimeslot: number;
    schedule: ScheduleData;
    timeSlotDuration: number;
    currentRoom: string;
}

export class StreamViewer extends React.Component<StreamViewerProps, StreamViewerState> {

    presenterCache: any;
    presenterInterval: number;

    constructor(props: StreamViewerProps) {
        super(props);

        const oneMinute = 60;

        this.presenterCache = {
            presenter: {},
            exhibitor: [],
            slogan: []
        };
        this.presenterInterval = -1;

        this.state = {
            
            currentTimeslot: 0,
            timeSlotDuration: 30 * oneMinute,
            schedule: {
                purple: [],
                red: [],
                blue: [],
                yellow: []
            },
            currentRoom: "purple"
        };
    }

    componentDidMount() {
        console.log("StreamViewer componentDidMount");
        let event = "test";
        let eventId = "1";

        this.loadStream(event); 
        this.loadSchedule(event);
        this.loadPresenters(eventId); 
    }

    /*
    Schedule related code
    */
    loadSchedule = (event: string) => {
        fetch("https://imsta.org/festa/get_schedule.php?event=" + event)
        .then((response) => response.text())
        .then((text) => this.storeSchedule(text))
        .catch((exception) => console.log(exception));
    };

    storeSchedule = (scheduleRaw: string) => {
        localStorage.setItem("schedule", scheduleRaw);
    
        let schedule = JSON.parse(scheduleRaw);
        this.setState({
            schedule: schedule
        });
        this.setupSchedule(schedule);
    };

    setupSchedule = (schedule: ScheduleData) => {
        /*
        Can use one general timeslot timer to fire off all timeslot events
        */
        setInterval(this.handleTimeslotEvent, this.state.timeSlotDuration * 1000);
    };

    handleTimeslotEvent = () => {
        //need up update each room's schedule
        //need to change each room's thumbnail
        //need to change presenter
        this.setState({
            currentTimeslot: this.state.currentTimeslot + 1
        });
        this.updatePresenters(this.presenterCache, this.state.currentRoom, this.state.currentTimeslot);
        /*this.loadRoomSchedules(this.schedule);
        this.updateThumbnails();*/
    };

    /*
    Stream related code
    */
    loadStream = (event: string) => {
        fetch("https://imsta.org/festa/get_stream.php?event=" + event)
        .then((response) => response.text())
        .then((text) => this.storeStreams(text))
        .catch((exception) => console.log(exception));
    };

    storeStreams = (streams: string) => {
        localStorage.setItem("streams", streams);
        this.setupIFrames();
    };

    setupIFrames = () => {
        console.log("setupIFrames");
        this.changeStream("purple");
    };    

    changeStream = (index: string) => {
        console.log("changeStream()");

        let streamPath = "https://www.youtube-nocookie.com/embed/";
        let streamArgs = "?rel=0&modestbranding=1&vq=hd1080&autoplay=1";
        let streams: {[key: string]: string} = {}
        
        try {
            streams = JSON.parse(localStorage.getItem("streams")!);
        
            if (streams == null) {
                throw new Error("Failed to load streams");
            }
        }
        catch {
            alert("Could not load streams");
            return;
        }

        let youtube = document.getElementById("youtube") as HTMLIFrameElement;

        if (youtube === null) {
            return;
        }

        youtube.src = streamPath + streams[index] + streamArgs;

        if (this.presenterCache != null) {
            this.updatePresenters(this.presenterCache, index, this.state.currentTimeslot);
        }
    };

    /*
    Presenter related code
    */
    loadPresenters = (event: string) => {
        fetch("https://imsta.org/festa/get_presenters.php?event=" + event)
        .then((response) => response.json())
        .then((json) => this.updatePresenters(json, "purple", 0))
        .catch((exception) => console.log(exception));
    };

    updatePresenters = (presenters: PresenterCache, room: string, timeslot: number) => {
        let mainPresenter = null;
        const roomPresenters = presenters["presenter"][room];

        if (roomPresenters === undefined) {
            return;
        }

        if (timeslot in roomPresenters) {
            mainPresenter = roomPresenters[timeslot];
        }
        else {
            //find the lowest next presenter
            let keys = Object.keys(roomPresenters);

            for (const indexName of keys) {
                const index = Number.parseInt(indexName);

                if (index >= timeslot) {
                    mainPresenter = roomPresenters[index];
                    break;
                }
            }

            if (mainPresenter == null) {
                mainPresenter = roomPresenters[Number.parseInt(keys[keys.length - 1])];
            }
        }

        let playlist = [
            mainPresenter
        ];
    
        let i = 0;
        let showedSlogan = false;
    
        for (let exhibitor of presenters["exhibitor"]) {
            playlist.push(exhibitor);
            playlist.push(mainPresenter);
            i++;
    
            if (i > 10) {
                playlist.push(presenters["slogan"][0]);
                i = 0;
                showedSlogan = true;
            }
        }
    
        if (!showedSlogan) {
            playlist.push(presenters["slogan"][0]);
        }
    
        let currentPlaylistIndex = 0;
    
        let showNextPresenter = () => {
            let picture = document.getElementById("presenter__picture") as HTMLImageElement;
            let name = document.getElementById("presenter__name");
            let description = document.getElementById("presenter__description");

            if (picture === null || name === null || description === null) {
                return;
            }
    
            let presenter = playlist[currentPlaylistIndex]

            if (presenter == null) {
                console.log("no presenter");
            }
    
            picture.src = "data:image/png;base64," + presenter["picture"];
            name.textContent = presenter["name"];
            description.innerHTML = presenter["description"];
    
            currentPlaylistIndex++;
    
            if (currentPlaylistIndex >= playlist.length) {
                currentPlaylistIndex = 0;
            }
        };
    
        showNextPresenter();
    
        if (this.presenterInterval !== 0) {
            clearInterval(this.presenterInterval);
        }
    
        const timeBetweenUpdates = 5 * 1000;
        /*this.setState({
            presenterCache: presenters,
            presenterInterval: window.setInterval(showNextPresenter, timeBetweenUpdates) 
        });*/
        this.presenterCache = presenters;
        this.presenterInterval = window.setInterval(showNextPresenter, timeBetweenUpdates);
    };

    render() {

        let fragment = null;
        let currentStream = this.props.fragment;
        let showSchedule = false;

        if (this.props.fragment.endsWith("/schedule")) {
            currentStream = this.props.fragment.split("/")[0];
            showSchedule = true;
        }

        if (showSchedule) {
            fragment = <Schedule 
            eventId={"1"}
            startTime={0} 
            currentTimeslot={0} 
            timeSlotDuration={10000000} 
            schedule={this.state.schedule} />;
        }
        else {
            fragment = <Stream />;
            setTimeout(() => this.changeStream(currentStream), 500);

        }

        return (
            <section>
                <div className="Submenu">
                    <p className="NoMargin RoomMenuLabel">Room</p>
                    <SubMenuItem header="Purple"
                        main="stream"
                        route="purple/schedule"
                        isActive={this.props.fragment === "purple"}/>
                    <SubMenuItem header="Red"
                        main="stream"
                        route="red/schedule"
                        isActive={this.props.fragment === "red"}/>
                    <SubMenuItem header="Blue"
                        main="stream"
                        route="blue/schedule"
                        isActive={this.props.fragment === "blue"}/>
                    <SubMenuItem header="Yellow"
                        main="stream"
                        route="yellow/schedule"
                        isActive={this.props.fragment === "yellow"}/>
                </div>

                {fragment}

            </section>
        )
    }
}