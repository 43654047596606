import React from 'react';
import './Login.css';
import './FormElements.css';

type LoginState = {
    email: string;
    password: string;
}

export class Login extends React.Component<any, LoginState> {
    constructor(props: any) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        } as LoginState);
    };

    login = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        fetch(process.env.REACT_APP_API_URL + '/Token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            }
        )
        .then((response) => {
            if (!response.ok) {
                throw new Error();
            }
            
            return response.json()
        })
        .then((data) => this.authorize(data))
        .catch((error) => alert("Wrong email or password, or account not activated"));
    };

    authorize(data: any) {
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        window.location.hash = "/now/news";
        setTimeout(() => window.location.reload(), 1500);
    }
    render() {
        return (
            <section className="MaxHeight">

                <form className="SignupForm MaxHeight" onSubmit={this.login}>
                    <label>Email</label>
                    <input required name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className="TextInput TextInput--Minimal"/>

                    <label>Password</label>
                    <input required name="password" type="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        className="TextInput TextInput--Minimal"/>

                    <button type="submit" 
                        className="Button Button--Primary AlignSelf--Center">Log in</button>

                    <p className="Text--Centered AlignSelf--End">Don't have an account? <a href="#/register">Register here</a></p>
                </form>
            </section>
        );
    }
}