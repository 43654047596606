import React from 'react';

export function SurveyStep(props: {step: number; active: number; completed: boolean}) {
    let className = "Survey-Step";

    if (props.active === props.step) {
        className += " Survey-Step--current";
    }

    if (props.completed) {
        return <div><i className="Survey-Step Survey-Step--completed fas fa-check"></i></div>;
    }
    else {
        return <p className={className}>{props.step}</p>;
    }
}

interface RadioProps {
    name: string; 
    label: string; 
    value?: string;
    current: string; 
    extraClass?: string;
    onChange: any
}

export function RadioButton(props: RadioProps) {
    let className = "ToggleBox";

    if (props.extraClass) {
        className += " " + props.extraClass;
    }

    let value = props.label;

    if (props.value) {
        value = props.value;
    }

    let input = <input type="radio" name={props.name} 
        value={value} checked={props.current === value} onChange={props.onChange}/>

    return (
        <div>
            <label>
                {input}
                <span className={className}>{props.label}</span>
            </label>
        </div>
    );
}

interface CheckboxProps {
    name: string; 
    label: string; 
    value?: string;
    current: string[];
    onChange: any;
}

export function CheckBox(props: CheckboxProps) {

    let value = props.label;

    if (props.value) {
       value = props.value;
    }

    let input = <input type="checkbox" name={props.name} 
        checked={props.current.includes(value)} onChange={props.onChange} value={value}/>;
   
    return (
        <div>
            <label>
                {input}
                <span className="ToggleBox">{props.label}</span>
            </label>
        </div>
    );
}

export function DisagreeAgreeSelector(props: {header: string; name: string, onChange: any, current: string}) {
    return (
        <div>
            <p className="Question">{props.header}</p>

            <div className="RadioGroup RadioGroup--Agreement">
                <p>Disagree</p>
                <RadioButton 
                    name={props.name} label="1" extraClass="AgreeButton"
                    current={props.current} onChange={props.onChange} />
                <RadioButton 
                    name={props.name} label="2" extraClass="AgreeButton"
                    current={props.current} onChange={props.onChange} />
                <RadioButton 
                    name={props.name} label="3" extraClass="AgreeButton"
                    current={props.current} onChange={props.onChange} />
                <RadioButton 
                    name={props.name} label="4" extraClass="AgreeButton"
                    current={props.current} onChange={props.onChange} />
                <RadioButton 
                    name={props.name} label="5" extraClass="AgreeButton"
                    current={props.current} onChange={props.onChange} />
                <p>Agree</p>
            </div>
        </div>
    );
}

interface LabelledInputProps {
    className: string;
    label: string;
    name: string;
    value: string;
    handleChange: any;
    pattern?: string;
}

export function LabelledInput(props: LabelledInputProps) {
    return (
        <div className="LabelledInputGroup ColumnGroup">
            <label className={props.className}>{props.label}</label>
            <input name={props.name} pattern={props.pattern}
                value={props.value} onChange={props.handleChange} />
        </div>
    );
}