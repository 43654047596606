import React from 'react';

export class ThankYou extends React.Component {

    render() {
        return (
            <section>
                <h1>Thank you!</h1>

                <p>Please check your email for an activation link from app@imsta.org to complete your registration.</p>

                <p>Regards,</p>
                <p>The IMSTA team</p>
            </section>
        );
    }
}