import { Token } from "./token";

export function authorizedFetch(url: string, method: string) {

    let token = new Token("access_token");

    return fetch(
        process.env.REACT_APP_API_URL + url, {
            method: method,
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${token.raw}`
            }
        }
    );
}

export function authorizedFetchWithBody(url: string, method: string, body: any, contentType?: string) {

    let token = new Token("access_token");
    let headers: any = {
        'Authorization': `Bearer ${token.raw}`
    };

    if (contentType) {
        headers["Content-Type"] = contentType;
    }

    return fetch(
        process.env.REACT_APP_API_URL + url, {
            method: method,
            mode: 'cors',
            headers: headers,
            body: body
        }
    );
}