import React from 'react';

type MenuItemProps = {
	header: string;
	route: string;
	icon: string;
	isActive: boolean;
}

class MenuItem extends React.Component<MenuItemProps> {
	render() {
		const link = "#/" + this.props.route;
		
		let className = "MenuItem";

		if (this.props.isActive) {
			className += " MenuItem--active";
		}

		switch (this.props.header) {
			case "Events":
			case "Tickets":
			case "Extras": {
				className += " MenuItem--Hidden";
			}
		}

		return (
			<a className={className} href={link}>
				<i className={`fas ${this.props.icon}`}></i>
				{this.props.header}
			</a>
		);
	}	
}

type SubMenuItemProps = {
	header: string;
	main: string;
	route: string;
	isActive: boolean;
}

export class SubMenuItem extends React.Component<SubMenuItemProps> {
	render() {
		const link = "#/" + this.props.main + "/" + this.props.route;
		
		let className = "SubMenuItem";

		if (this.props.isActive) {
			className += " SubMenuItem--active"
		}

		return (
			<a className={className} href={link}>				
				{this.props.header}
			</a>
		);
	}	
}

type MenuProps = {
    active: string
}

export class Menu extends React.Component<MenuProps> {
	render() {
		return (
			<nav className="Menu">
				<MenuItem header="What's On" 
					route="now" 
					icon="fa-calendar-day"
					isActive={this.props.active === "now" || this.props.active === ""} />
				<MenuItem header="Events" 
					route="events" 
					icon="fa-calendar-alt"
					isActive={this.props.active === "events"} />
				<MenuItem header="Stream" 
					route="stream" 
					icon="fa-ticket-alt"
					isActive={this.props.active === "stream"} />
				<MenuItem header="Extras" 
					route="extras" 
					icon="fa-question-circle"
					isActive={this.props.active === "extras"} />
				<MenuItem 
					header="Profile" 
					route="profile" 
					icon="fa-user-circle"
					isActive={this.props.active === "profile"} />
			</nav>
		);
	}
}