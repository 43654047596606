interface TokenHeader {
    typ: "JWT";
    alg: string
}

interface TokenPayload {
    nbf: number;
    exp: number;
    iat: number;
}

/*
Tokens are split into two JSON objects and a signature, then base64 encoded.
Need to split the encoded string on ".", then only decode the first two
array elements

"header.payload.signature"

header: {
    typ: "JWT",
    alg: string
}

payload: {
    nbf: unix timestamp, "not before"
    exp: unix timestamp, "not after",
    iat: unix timestamp, "issued at"
}
*/
export class Token {

    header: TokenHeader;
    payload: TokenPayload;
    name: string;
    raw: string;

    constructor(name: string) {
        this.name = name;
        let token = localStorage.getItem(name);

        if (token === null) {
            throw new Error("Token not found");
        }

        this.raw = token;

        let parts = token.split(".");
        
        if (parts.length !== 3) {
            throw new Error("Invalid token");
        }

        this.header = JSON.parse(atob(parts[0]));

        if (this.header == null) {
            throw new Error("Invalid token header");
        }

        this.payload = JSON.parse(atob(parts[1]));

        if (this.payload == null) {
            throw new Error("Invalid token payload");
        }
    }

    isValid() {
        if (Date.now() >= this.payload.exp * 1000) {
            return false;
        }
        else if (Date.now() < this.payload.nbf * 1000) {
            return false;
        }
        else {
            return true;
        }
    }

    tryRefresh(refreshToken: string) {
        let rawToken = localStorage.getItem(this.name);

        return fetch(
            'https://localhost:5001/api/Token', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify({
                    accessToken: rawToken,
                    refreshToken: refreshToken
                })
            }
        )
        .then((response) => response.json())
        .then((data) => this.handleRefreshResponse(data))
        .catch((error) => {alert(error); return false;});
    }

    handleRefreshResponse(data: any) {
        localStorage.setItem('access_token', data.access);
        //TODO: make new refresh token too?
        return true;
    }
}