import React from 'react';
import './App.css';
import { Menu } from "./Components/Menu";
import { Account } from "./Components/Account";
import { StreamViewer } from "./Components/StreamViewer";
import { Something } from "./Components/Something";
import { CurrentEvent } from "./Components/CurrentEvent";
import { AllEvents } from "./Components/AllEvents";
import { Login } from "./Components/Login";
import { Register } from "./Components/Register";
import { Token } from "./token";
import { ThankYou } from "./Components/ThankYou";
import { Activate } from "./Components/Activate";

interface RoutedComponent {
	name: string; 
	component: typeof React.Component
}

interface Route {
	main: string; //Top-level navigation, eg what's on, events, tickets
	sub: string; //Second-level navigation, eg what's on => news
}

type AppProps = {}
type AppState = {
	route: Route;
	loggedIn: boolean;
}

function getRouteFromHash() {
	let hash = window.location.hash;
	let matches = hash.match(/#?\/(\w+)(?:\/([\w-/]+))?/);

	if (matches === null) {
		return {main: "", sub: ""};
	} 

	return {
		main: matches[1],
		sub: matches[2] ?? "news"
	};
}

class App extends React.Component<AppProps, AppState> {

	routes: Map<string, RoutedComponent>;
	deferredPrompt: any;

	constructor(props: any) {
		super(props);

		let route = getRouteFromHash();

		if (route && route.main === "logout") {
			localStorage.clear();
			window.location.hash = "";
			setTimeout(() => window.location.reload(), 1000);
		}

		this.state = {
			route: route,
			loggedIn: this.checkLogin()//localStorage.getItem("access_token") != null
		};

		this.routes = new Map<string, RoutedComponent>([
			["", {name: "", component: CurrentEvent} as RoutedComponent],
			["current", {name: "current", component: CurrentEvent} as RoutedComponent],
			["events", {name: "events", component: AllEvents} as RoutedComponent],
			["stream", {name: "stream", component: StreamViewer} as RoutedComponent],
			["extras", {name: "something", component: Something} as RoutedComponent],
			["profile", {name: "account", component: Account} as RoutedComponent],
			["register", {name: "register", component: Register}],
			["thankyou", {name: "thankyou", component: ThankYou}],
			["activate", {name: "activate", component: Activate}]
		]);

		window.onpopstate = this.stateChanged;

		document.addEventListener('DOMContentLoaded', function () {

			let app = document.querySelector(".App")! as HTMLElement;
			let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			let mainHeight = height - 70 - 64;

			let appNotLoggedIn = document.querySelector(".App--NotLoggedIn") as HTMLElement;
			
			if (appNotLoggedIn) {
				mainHeight = height - 164;
				app.style.gridTemplateRows = '164px ' + mainHeight + 'px';
			}
			else {
				app.style.gridTemplateRows = '70px ' + mainHeight + 'px 64px';
			}

			let viewer = document.querySelector(".Viewer") as HTMLElement;
			let fragment = document.querySelector(".Fragment") as HTMLElement;

			if (viewer && fragment) {
				mainHeight = mainHeight - 30;// - 30 - 74;
				fragment.style.gridTemplateRows = mainHeight + 'px 32px 42px'
			}
		});


		window.addEventListener('beforeinstallprompt', (e) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			//e.preventDefault();
			// Stash the event so it can be triggered later.
			this.deferredPrompt = e;
			//showInstallPromotion();
		});
	}

	checkLogin() {

		try {
			let token = new Token("access_token");

			if (token.isValid()) {
				return true;
			}
			else {
				let refreshToken = localStorage.getItem('refresh_token');

				if (refreshToken === null) {
					return false;
				}

				//return token.tryRefresh(refreshToken);
			}
		}
		catch {
			return false;
		}

		return false;
	}

	stateChanged = () => {
		let route = getRouteFromHash();

		const isLoggedIn = this.checkLogin(); //true;//localStorage.getItem("access_token") != null;

		this.setState({
			route: route,
			loggedIn: isLoggedIn
		});
	};

	render() {

		if (this.state.loggedIn) {
			return this.renderLoggedIn();
		}
		else {
			return this.renderLoggedOut();
		}
	}

	renderLoggedOut() {
		let route = this.routes.get(this.state.route.main);
		let mainComponent = null;

		if (route && route.name === "thankyou") {
			mainComponent = <ThankYou />;
		}
		else if (route && route.name === "activate") {
			mainComponent = <Activate code={this.state.route.sub} />;
		}
		else if (!route || route.name !== "register") {
			mainComponent = <Login />;
		}
		else {
			mainComponent = <Register />;
		}

		return (
			<main className="App App--NotLoggedIn">
				 <header className="AppBar AppBar--NotLoggedIn">
					<img src="/app/IMSTA_LOGO_2020.png"
						alt="IMSTA logo"
						width="100" />
				</header>

				<section className="Viewer Viewer--NotLoggedIn">
					{mainComponent}
				</section>
			</main>
		);
	}

	renderLoggedIn() {
		let route = this.routes.get(this.state.route.main);
		let mainComponent = null;

		if (!route || route.name === "activate") {
			mainComponent = <CurrentEvent fragment={this.state.route.sub}/>;
		}
		else {
			mainComponent = <route.component fragment={this.state.route.sub} />;
		}

		return (
			<main className="App">
				<header className="BarContainer">
					<div className="AppBar">

						<div className="LogoAndName">
							<img src={process.env.PUBLIC_URL + '/IMSTA_LOGO_2020.png'}
								alt="IMSTA logo"
								width="50" />

							<img src={process.env.PUBLIC_URL + '/IMSTA-Name-WHT.png'}
								className="AppBar__Slogan"
								alt="IMSTA logo"
								/>	
						</div>

						<img src={process.env.PUBLIC_URL + '/btsyu.png'}
							className="AppBar__Slogan"
							alt="IMSTA logo"
							 />
					</div>
				</header>

				<section className="Viewer">
					{mainComponent}
				</section>

				<Menu active={this.state.route.main} />
			</main>
		);
	}
}

export default App;